import { useLayoutEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import AuthLayout from 'src/common/layouts/AuthLayout/AuthLayout'
import ResetPasswordPage from 'src/common/pages/Auth/ResetPage'
import SelectOrganizationPage from 'src/common/pages/Auth/SelectOrganizationPage/SelectOrganizationPage'
import AttachmentPage from 'src/common/pages/Private/AttachmentPage'
import NotFoundPage from 'src/common/pages/Stuff/NotFoundPage/NotFoundPage'
import { CRMTemplate } from 'src/common/templates'

import { BENESTREAM_ORG_ID } from './common/config'
import { NotificationProvider } from './common/notification'
import { benestreamRoutes, defaultRoutes } from './router'
import ForgotView from './v2/domains/auth/views/ForgotView'
import LoginView from './v2/domains/auth/views/LoginView'
import { useAppDispatch, useAppSelector } from './v2/store/hooks'

const defaultRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/login',
        element: <LoginView />,
      },
      {
        path: '/forgot',
        element: <ForgotView />,
      },
      {
        path: '/select-organization',
        element: <SelectOrganizationPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: ':organizationId',
    element: (
      <NotificationProvider>
        <CRMTemplate />
      </NotificationProvider>
    ),
    children: defaultRoutes,
  },
  {
    path: 'attachment/:attachmentId/:attachmentType',
    element: <AttachmentPage />,
  },
  {
    path: 'not-found',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

const benestreamRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/login',
        element: <LoginView />,
      },
      {
        path: '/forgot',
        element: <ForgotView />,
      },
      {
        path: '/select-organization',
        element: <SelectOrganizationPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: ':organizationId',
    element: <CRMTemplate />,
    children: benestreamRoutes,
  },
  {
    path: 'attachment/:attachmentId/:attachmentType',
    element: <AttachmentPage />,
  },
  {
    path: 'not-found',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

const AppRoutes = () => {
  const dispatch = useAppDispatch()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentRouter =
    userProfile.organization.id === BENESTREAM_ORG_ID
      ? benestreamRouter
      : defaultRouter

  useLayoutEffect(() => {
    dispatch({ type: 'CHECK_ROUTE' })
  }, [])

  return <RouterProvider router={currentRouter} />
}

export default AppRoutes
