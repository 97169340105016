import { TextInput } from '@mantine/core'
import { GetInputPropsReturnType } from '@mantine/form/lib/types'

import classes from './filter-textInput.module.scss'

export interface TextFilterProps extends GetInputPropsReturnType {
  placeholder: string
  debounceFunc?: (event: string) => void
}

const TextInputFilter = ({
  debounceFunc,
  placeholder,
  onChange,
  ...props
}: TextFilterProps) => {
  return (
    <TextInput
      placeholder={placeholder}
      {...props}
      classNames={{
        input: classes.input,
        section: classes.input,
      }}
      onChange={(event) => {
        onChange(event.target.value)
        if (debounceFunc) {
          debounceFunc(event.target.value)
        }
      }}
    />
  )
}

export default TextInputFilter
