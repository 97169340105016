import { useParams } from 'react-router-dom'

import { useGetUserQuery } from '../models/user.api'
import { MembershipEmailDomainUser } from '../models/user.model'

interface UseUserInformationUseCase {
  emailDomains: MembershipEmailDomainUser[]
}

const useUserEmailDomainsUseCase = (): UseUserInformationUseCase => {
  const { userId = '' } = useParams()
  const { data: user } = useGetUserQuery(userId)

  return {
    emailDomains: user?.emailDomains ?? [],
  }
}

export default useUserEmailDomainsUseCase
