import cx from 'classnames'
import { useFormik } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { Button, Form, Icons, SubmitButton } from 'src/common/components'
import QuillEditor from 'src/common/components/form/QuillEditor'
import { useMutatePersonNote } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './person-note-modal.module.scss'

interface PersonNoteFormProps {
  onAfterSave: () => void
}

const PersonNoteModal = (props: PersonNoteFormProps) => {
  const { onAfterSave } = props

  const { personId } = useParams<{ personId: string }>()

  const noteMutation = useMutatePersonNote('create')

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      await noteMutation.mutateAsync({
        ...values,
        personId: personId,
      })

      onAfterSave()
    },
    validationSchema: yup.object().shape({
      message: yup
        .string()
        .required('Note cannot be empty')
        .test('empty', 'Note cannot be empty', (value) => {
          return value?.replace(/<[^>]*>?/gm, '').trim() !== ''
        }),
    }),
  })

  return (
    <>
      <div className={styles.wrapper}>
        <div className={cx(styles.icon, styles.error)}>
          <Icons.Warning />
        </div>

        <div className={styles.inner}>
          <div className={styles.title}>Case Note Required</div>
          <div className={styles.description}>
            <p>
              <b>You have not added a Case Note.</b> Please enter your notes
              below or select "Continue" to proceed without adding a note.
            </p>
          </div>
        </div>
      </div>
      <Form providerValue={formik}>
        <div className={styles.inner}>
          <QuillEditor
            required
            name="message"
            label="Note"
            placeholder="Enter your note"
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Button className={styles.altButton} onClick={() => onAfterSave()}>
            Continue
          </Button>

          <SubmitButton
            disabled={formik.values.message === ''}
            className={styles.saveButton}
            loading={formik.isSubmitting}
          >
            Save Note
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonNoteModal,
  'is_update_person_note_enabled',
)
