import { BasicBlock, Breadcrumbs } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './dashboard.module.scss'

const DashboardPage = () => {
  return (
    <>
      <Breadcrumbs items={[{ label: '' }]} />

      <BasicTemplate title="Dashboard" isLoading={false}>
        <BasicBlock>
          <h3>Overview</h3>
          <div className={styles.gridReports}>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.18.16.png" />
            </div>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.18.30.png" />
            </div>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.18.40.png" />
            </div>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.18.50.png" />
            </div>
          </div>
          <div className={styles.gridReports}>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.18.58.png" />
            </div>
            <div style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>
              <img
                style={{ maxWidth: '600px', minWidth: '400px', width: '100%' }}
                src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.19.05.png"
              />
            </div>
            <div>
              <img src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.32.33.png" />
            </div>
          </div>

          <h3>Cards</h3>
          <div className={styles.gridReports2}>
            <div>
              <img
                style={{ maxWidth: '400px', minWidth: '400px' }}
                src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.19.39.png"
              />
            </div>
            <div>
              <img
                style={{ maxWidth: '400px', minWidth: '400px' }}
                src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-25+at+11.19.47.png"
              />
            </div>
          </div>
          <h3>NYC Drivers Postal Code</h3>
          <div className={styles.flexOne}>
            <div>
              <img
                style={{ width: '100%' }}
                src="https://idg-avatars.s3.amazonaws.com/Screenshot+2024-04-29+at+23.47.49.png"
              />
            </div>
          </div>
        </BasicBlock>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DashboardPage,
  'is_get_dashboard_enabled',
)
