import { usePersonTagQuery } from 'src/v2/domains/person/models/person.api'

export interface IPersonsTags {
  personTags: string[] | []
}

const usePersonTagsUseCase = () => {
  const { data } = usePersonTagQuery({})

  return {
    personTags: data?.records.map(({ name }) => name) || [],
  }
}

export default usePersonTagsUseCase
