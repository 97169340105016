import { Checkbox, Table } from '@mantine/core'
import PolicyCheckHOC from '@v2/commons/HOC/policyCheckHOC'
import { AssignPersonModel } from '@v2/domains/person/models/assignPerson.model'

import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/v2/commons/utils/navigation'
import { getPhoneWithMask } from 'src/v2/commons/utils/phone'
import { removeUnderScoreAndTransformToLowerCase } from 'src/v2/commons/utils/stringManipulation'
import { formatDate } from 'src/v2/commons/utils/time'
import { type IEnrollment } from 'src/v2/domains/person/models/enrollment.model'
import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import {
  type PersonModel,
  type PersonsFiltersModel,
} from 'src/v2/domains/person/models/person.model'
import {
  getLeadStatusColor,
  getPersonDocuments,
  getPreferredLanguage,
  mapEnrollmentStatus,
} from 'src/v2/domains/person/utils'

import PersonTableABCs from '../ABCsPersonColumn/ABCsPersonTable.component'
import { TableLeadStatus } from '../StatusColumn'
import classes from '../table.module.scss'

interface PersonsTableProps {
  page: 'accounts' | 'leads'
  persons: PersonsModel['records']
  getFilters: () => PersonsFiltersModel
  handleAddRemovePerson: (
    person: Pick<PersonModel, 'id'>['id'],
    addPerson: boolean,
  ) => void
  handleSelectAllPersons: (payload: {
    isAllSelected: boolean
    allSelectedPersons: AssignPersonModel['filters']['selectedItems']
  }) => void
  isAllSelected: boolean
  selectedItems: PersonModel['id'][]
  personsIds: string[]
}

const PersonTable = ({
  page,
  persons,
  getFilters,
  isAllSelected,
  handleAddRemovePerson,
  handleSelectAllPersons,
  selectedItems,
  personsIds,
}: PersonsTableProps) => {
  const router = useRouter()
  const navigateToPersonById = (item: PersonsModel['records'][0]) => {
    router.push(`${PRIVATE_ROUTES[page.toUpperCase()].path}/${item.id}`)
  }

  const {
    companies,
    preferredLanguage,
    rating,
    zipCode,
    isUnassigned,
    fromDate,
    city,
    tags,
  } = getFilters()

  const enableSelection = [
    companies,
    preferredLanguage,
    rating,
    zipCode,
    isUnassigned,
    fromDate,
    city,
    tags?.length,
  ].some((filter) => !!filter)

  const handleSelectLead = (
    event: React.ChangeEvent<HTMLInputElement>,
    person: Pick<PersonModel, 'id'>['id'],
  ) => {
    const { checked } = event.target
    handleAddRemovePerson(person, checked)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    handleSelectAllPersons({
      isAllSelected: checked,
      allSelectedPersons: personsIds,
    })
  }

  const isPersonSelected = (searchId: string) => {
    return !!selectedItems.find((selectedId) => selectedId === searchId)
  }

  const getPersonsTags = (personTags: PersonModel['tags'] | undefined) => {
    return personTags ? personTags.map(({ name }) => name).join(' | ') : ''
  }

  return (
    <Table
      layout="fixed"
      classNames={{
        table: classes.table,
        thead: classes['table-head'],
        th: classes['table-th'],
        td: classes['table-td'],
      }}
      highlightOnHover={true}
      withTableBorder={true}
      withColumnBorders={true}
    >
      <Table.Thead>
        <Table.Tr>
          {enableSelection ? (
            <Table.Th>
              <Checkbox
                className={classes.checkbox}
                radius="3px"
                size="18px"
                onChange={handleSelectAll}
                checked={isAllSelected}
              />
            </Table.Th>
          ) : (
            <PolicyCheckHOC policyName="is_merge_persons_enabled">
              <Table.Th>
                <Checkbox
                  className={classes.checkbox}
                  radius="3px"
                  size="18px"
                  onChange={handleSelectAll}
                  checked={isAllSelected}
                />
              </Table.Th>
            </PolicyCheckHOC>
          )}
          <Table.Th>Name</Table.Th>
          {preferredLanguage ? (
            <Table.Th>Preferred Language</Table.Th>
          ) : (
            <Table.Th>ABC</Table.Th>
          )}
          {rating ? <Table.Th>Rating</Table.Th> : <Table.Th>Status</Table.Th>}
          {zipCode ? (
            <Table.Th>Postal Code</Table.Th>
          ) : (
            <Table.Th>Driver's License Number</Table.Th>
          )}
          {companies ? (
            <Table.Th>Company</Table.Th>
          ) : (
            <Table.Th>Email</Table.Th>
          )}
          {isUnassigned ? (
            <Table.Th>Assigned</Table.Th>
          ) : (
            <Table.Th>Mobile Phone</Table.Th>
          )}
          {city && <Table.Th>City</Table.Th>}
          {!!tags?.length && <Table.Th>Tags</Table.Th>}
          {fromDate && <Table.Th>Created Date</Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {persons.map((person, idx) => (
          <Table.Tr
            key={`${person.firstName}-${idx}`}
            className={classes['table-tr']}
          >
            {enableSelection ? (
              <Table.Td>
                <Checkbox
                  className={classes.checkbox}
                  radius="3px"
                  size="18px"
                  checked={isPersonSelected(person.id)}
                  onChange={(e) => handleSelectLead(e, person.id)}
                />
              </Table.Td>
            ) : (
              <PolicyCheckHOC policyName="is_merge_persons_enabled">
                <Table.Td>
                  <Checkbox
                    className={classes.checkbox}
                    radius="3px"
                    size="18px"
                    checked={isPersonSelected(person.id)}
                    onChange={(e) => handleSelectLead(e, person.id)}
                  />
                </Table.Td>
              </PolicyCheckHOC>
            )}
            <Table.Td fw="600" onClick={() => navigateToPersonById(person)}>
              {person.firstName + ' ' + person.lastName}
            </Table.Td>
            {preferredLanguage ? (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {getPreferredLanguage(person.preferredLanguage)}
              </Table.Td>
            ) : (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                <PersonTableABCs enrollments={person.enrollments || []} />
              </Table.Td>
            )}
            {rating ? (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {!person.rating ? 'Unrated' : person.rating}
              </Table.Td>
            ) : (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {person.enrollments?.map(
                  (enrollment: IEnrollment, idx: number) => (
                    <TableLeadStatus
                      key={idx}
                      status={removeUnderScoreAndTransformToLowerCase(
                        mapEnrollmentStatus(
                          enrollment.status,
                          enrollment.associatedBenefitContract.organization.id,
                        ),
                      )}
                      color={getLeadStatusColor(
                        removeUnderScoreAndTransformToLowerCase(
                          mapEnrollmentStatus(
                            enrollment.status,
                            enrollment.associatedBenefitContract.organization
                              .id,
                          ),
                        ),
                      )}
                    />
                  ),
                )}
              </Table.Td>
            )}
            {zipCode ? (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {person.postalCode}
              </Table.Td>
            ) : (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {getPersonDocuments(person.documents).driverLicense}
              </Table.Td>
            )}
            {companies ? (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {companies}
              </Table.Td>
            ) : (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {person.email}
              </Table.Td>
            )}
            {isUnassigned ? (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {isUnassigned === 'false' ? 'Yes' : 'No'}
              </Table.Td>
            ) : (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {getPhoneWithMask(person.phoneNumber)}
              </Table.Td>
            )}
            {city && <Table.Td>{person.city}</Table.Td>}
            {!!tags?.length && (
              <Table.Td>{getPersonsTags(person.tags)}</Table.Td>
            )}
            {fromDate && (
              <Table.Td onClick={() => navigateToPersonById(person)}>
                {formatDate(person.createdAt, 'MM/DD/YYYY')}
              </Table.Td>
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

export default PersonTable
