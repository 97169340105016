import DOMPurify from 'dompurify'

import styles from './htmlPreview.module.scss'

interface HtmlPreviewProps {
  content: string
  className?: string
}

const HtmlPreview = ({ content, className }: HtmlPreviewProps) => {
  const sanitizedContent = DOMPurify.sanitize(content, {
    ADD_TAGS: ['style'],
    ADD_ATTR: ['class', 'id'],
    FORCE_BODY: true,
  })

  return (
    <div
      className={`${styles.htmlPreview} ${className || ''}`}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  )
}

export default HtmlPreview
