import { WbfPolicies } from '@workers-benefit-fund/shared-data'

import { IRunProcessItem } from '../../run-process/models/RunProcess.model'

export const STATIC_DATA_PROCESS: IRunProcessItem[] = [
  {
    id: 'uuid-mock-1',
    name: 'Process ESD Response File',
    url: '/v1/washington-state/process-provider-resonance',
    type: 'static',
    policy: WbfPolicies.is_washington_state_build_report_enabled,
    description: 'Process ESD response file with Unique IDs and errors',
    successMessage:
      'We are processing the data. You will get a report in an email.',
    parameters: [
      {
        label: 'Upload csv file',
        name: 'fileKey',
        type: 'file',
        source: 'WA_ESD_RESPONSE_FILE',
        saveFile: true,
      },
    ],
  },
  {
    id: 'uuid-mock-5',
    name: 'Process PFML matchback file CSV data',
    url: '/v1/washington-state/process-matchback-file',
    type: 'static',
    policy: WbfPolicies.is_washington_state_process_matchback_file_enabled,
    description:
      'Save the matchback records and persist the relationship to the driver.',
    successMessage:
      "We're processing your request. You will get the report in your email.",

    parameters: [
      {
        label: 'Upload csv matchback file',
        name: 'fileKey',
        type: 'file',
        source: 'WA_MATCHBACK_FILE',
        saveFile: true,
      },
      {
        label: 'Select the TNC source',
        name: 'tncSource',
        options: ['Uber', 'Lyft', 'HopSkipDrive', 'Via'],
        type: 'select',
        required: true,
        validate: (value: any) => {
          if (!value) {
            return 'Please select the TNC source'
          }
          return null
        },
      },
    ],
  },

  {
    id: 'uuid-mock-6',
    name: 'Process AON Metlife ID file',
    url: '/v1/aon/process-provider-resonance',
    type: 'static',
    policy: 'is_aon_process_provider_resonance_enabled',
    description: 'TODO',
    successMessage:
      "We're processing your request. You will get the report in your email.",

    parameters: [
      {
        label: 'Upload csv file',
        name: 'fileKey',
        type: 'file',
        source: 'AON_METLIFE_FILE',
        saveFile: true,
      },
    ],
  },
  {
    id: 'uuid-mock-7',
    name: 'Insert AON file',
    url: '/v1/aon/sync-data',
    type: 'static',
    policy: 'is_aon_process_provider_resonance_enabled',
    description: 'TODO',
    successMessage:
      "We're processing your request. You will get the report in your email.",

    parameters: [
      {
        label: 'Upload csv file',
        name: 'fileKey',
        type: 'file',
        source: 'AON_METLIFE_FILE',
        saveFile: true,
      },
    ],
  },
  {
    id: 'uuid-mock-8',
    name: 'Process TNCs earnings file',
    url: '/v1/washington-state/process-tnc-earnings-file',
    type: 'static',
    policy: 'is_washington_state_process_tnc_earnings_file_enabled',
    description: 'Process TNCs earnings file',
    successMessage:
      "We're processing your request. You will get the report in your email.",
    parameters: [
      {
        label: 'Upload earnings report',
        name: 'fileKey',
        type: 'file',
        source: 'WA_EARNINGS_FILE',
        saveFile: true,
      },
      {
        label: 'Select the TNC source',
        name: 'tncSource',
        options: ['Uber', 'Lyft', 'HopSkipDrive', 'Via'],
        type: 'select',
        required: true,
        validate: (value: any) => {
          if (!value) {
            return 'Please select the TNC source'
          }
          return null
        },
      },
      {
        label: 'Select the quarter',
        name: 'quarter',
        options: ['Q1', 'Q2', 'Q3', 'Q4'],
        type: 'select',
        required: true,
        validate: (value: any) => {
          if (!value) {
            return 'Please select the quarter'
          }
          return null
        },
      },
      {
        label: 'Select the year',
        name: 'year',
        type: 'year-picker',
        required: true,
        validate: (value: any) => {
          if (!value) {
            return 'Please select the year'
          }
          return null
        },
      },
    ],
    onCustomError: {
      message:
        'Would you like to proceed with the process? This will overwrite the existing earnings file.',
      matchRegex: /already been reported/,
      values: { overrideEarnings: true },
    },
  },
  /*      {
          id: 'uuid-mock-1',
          name: 'AllyHealth Reconciliation',
          url: '/v1/washington-state/build-report',
          type: 'static',
          description:
            'Run AllyHealth synchronization. You will get report in Slack #alerts',
          successMessage: '',
          parameters: [],
        },
        {
          id: 'uuid-mock-1',
          name: 'Process MetLife Benefit Utilization CSV file',
          url: '/v1/washington-state/build-report',
          type: 'static',
          description:
            'Update MetLife Benefit Utilization CSV file',
          successMessage: '',
          parameters: [],
        },
        {
          id: 'uuid-mock-1',
          name: 'Process GVS Benefit Utilization CSV file',
          url: '/v1/washington-state/build-report',
          type: 'static',
          description:
            'Update GVS Benefit Utilization CSV file',
          successMessage: '',
          parameters: [],
        },*/
]
