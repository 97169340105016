import {
  MultiSelect as ComboxMultiSelect,
  type MultiSelectProps,
} from '@mantine/core'

import { Icons } from 'src/common/components/ui'

import styles from './filter-multi-select.module.scss'

interface MultiSelectFilterProps extends MultiSelectProps {
  areSelected?: boolean
}

const MultiSelectFilter = ({
  areSelected,
  ...props
}: MultiSelectFilterProps) => {
  return (
    <ComboxMultiSelect
      checkIconPosition="right"
      rightSection={!areSelected && <Icons.ArrowSelect />}
      comboboxProps={{
        withinPortal: false,
        position: 'bottom',
        middlewares: { flip: false, shift: false },
        offset: 0,
        transitionProps: { transition: 'scale-y', duration: 300 },
      }}
      withScrollArea={false}
      classNames={{
        input: styles.input,
        inputField: styles.inputField,
        section: styles.section,
        dropdown: styles.dropdown,
      }}
      {...props}
    />
  )
}

export default MultiSelectFilter
