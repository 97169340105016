import { Flex, Modal, Text } from '@mantine/core'
import { WbfPolicies } from '@workers-benefit-fund/shared-data'
import { useState } from 'react'

import { BasicBlock, Breadcrumbs, Button, Table } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../store/hooks'
import RunProcessWithParametersModal from '../../run-process/components/RunProcessWithParametersModal.component'
import { IRunProcessItem } from '../../run-process/models/RunProcess.model'
import { STATIC_DATA_PROCESS } from '../data/DataProcess.data'
import styles from './data-process.module.scss'

const DataProcessingPage = () => {
  const [runModalWithItemOpen, setModalWithItemOpen] =
    useState<IRunProcessItem | null>(null)
  const { policies } = useAppSelector((state) => state.user.userProfile)
  const filteredReportsByPolicy = STATIC_DATA_PROCESS.filter(
    (item) => item.policy && policies.includes(item.policy),
  )
  return (
    <>
      <Breadcrumbs items={[{ label: '' }]} />

      <BasicTemplate title="Data Processing" isLoading={false}>
        <BasicBlock>
          <Table>
            {filteredReportsByPolicy.map((item: IRunProcessItem) => {
              return (
                <Table.Row key={item.id} id={item.id}>
                  <Table.Column className={styles.name} title="Name" showMobile>
                    {item.name}
                  </Table.Column>

                  <Table.Column title="Description" showMobile>
                    {item.description}
                  </Table.Column>

                  <Table.Column title="Actions" showMobile>
                    <Flex align="center" justify="center">
                      <Button
                        className={styles.button}
                        icon="CloudUpload"
                        size="small"
                        onClick={() => {
                          setModalWithItemOpen(item)
                        }}
                      >
                        Upload File
                      </Button>
                    </Flex>
                  </Table.Column>
                </Table.Row>
              )
            })}
          </Table>
          {
            <Modal
              opened={!!runModalWithItemOpen}
              onClose={() => setModalWithItemOpen(null)}
              title={
                <Text size="md" fw={500} ml={16}>
                  {runModalWithItemOpen?.name || 'Run Process'}
                </Text>
              }
            >
              <RunProcessWithParametersModal
                initialData={runModalWithItemOpen!}
                onClose={() => setModalWithItemOpen(null)}
              />
            </Modal>
          }
        </BasicBlock>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DataProcessingPage,
  WbfPolicies.is_enable_data_processing,
  true,
)
