import { DatesRangeValue } from '@mantine/dates'
import { type UseFormReturnType } from '@mantine/form/lib/types'

import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import {
  PersonModel,
  type PersonsFiltersModel,
} from 'src/v2/domains/person/models/person.model'
import useAssignAccountUseCase from 'src/v2/domains/person/useCases/accounts/useAssignAccount.useCase'

import { AssignPersonModel } from '../../models/assignPerson.model'
import usePersonsUseCase from '../../useCases/usePersons.useCase'
import usePersonTagsUseCase from '../../useCases/usePersonsTags.useCase'

interface IUseAccountsContainer {
  data: PersonsModel | undefined
  isLoading: boolean
  isSuccess: boolean
  isFetching: boolean
  personsFiltersForm: UseFormReturnType<PersonsFiltersModel>
  handleDateRangeChange: (value: DatesRangeValue) => void
  clearAllFilters: () => void
  handlePaginate: () => void
  handleAddRemoveAccount: (
    account: Pick<PersonModel, 'id'>['id'],
    addAccount: boolean,
  ) => void
  selectedItems: Pick<PersonModel, 'id'>['id'][]
  handleSelectAllAccounts: (payload: {
    isAllSelected: boolean
    allSelectedPersons: AssignPersonModel['filters']['selectedItems']
  }) => void
  isProgrammaticFormUpdateRef: React.MutableRefObject<boolean>
  handleClearStoreAccount: () => void
  personsIds: string[]
  isAssignMultipleTasksEnabled: boolean
  personTags: string[] | []
}

const useAccountsContainer = (): IUseAccountsContainer => {
  const {
    data,
    personsQueryStatus: { isLoading, isSuccess, isFetching },
    personsFiltersForm,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    isProgrammaticFormUpdateRef,
    personsIds,
    isAssignMultipleTasksEnabled,
  } = usePersonsUseCase()

  const {
    handleAddRemoveAccount,
    selectedItems,
    handleSelectAllAccounts,
    handleClearStoreAccount,
  } = useAssignAccountUseCase()

  const { personTags } = usePersonTagsUseCase()

  return {
    isLoading,
    isSuccess,
    isFetching,
    data,
    personsFiltersForm,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    isProgrammaticFormUpdateRef,
    handleAddRemoveAccount,
    selectedItems,
    handleSelectAllAccounts,
    handleClearStoreAccount,
    personsIds,
    isAssignMultipleTasksEnabled,
    personTags,
  }
}

export default useAccountsContainer
