import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import { Form } from 'src/common/components'
import { yupPhoneValidator } from 'src/common/helpers'
import { IPersonMutationData } from 'src/common/interfaces'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import {
  useGetPersons,
  useMutatePerson,
  useMutatePersonTagBlackCarFund,
} from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
//TODO refactor it and change to dynamic import of current Step
import BottomButtons from './BottomButtons'
import * as Steps from './Steps'

const STEPS_ORDER = ['Main', 'Address'] //Extra  //TODO mb refactor and remove it

const CreateLeadForm = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const isRequiredRating = userProfile.policies.some(
    (policy) => policy === 'is_rating_enabled',
  )

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [result, setResult] = useState<string | null>(null)
  const [descriptionModalWithError, setDescriptionModalWithError] = useState<
    string | undefined
  >()
  const [newPersonId, setnewPersonId] = useState<string | null>()

  const mutationPersonTagBlackCarFund = useMutatePersonTagBlackCarFund()

  const router = useRouter()

  const leadMutation = useMutatePerson('create')

  const formik = useFormik<IPersonMutationData>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      otherPhoneNumber: '',
      companies: [],
      street: '',
      city: '',
      postalCode: '',
      driversLicenseNumber: '',
      tlcLicenseNumber: '',
      source: 'Driver360-AdminPanel',
      birthDate: '',
    },
    isInitialValid: false,
    onSubmit: async (values) => {
      const newLeadValues = {
        ...values,
        birthDate: values.birthDate || undefined,
      }

      delete newLeadValues.blackCarFund

      //TODO remove this hack related to Checkboxes of SelectContract !!!!!!!!
      Object.keys(newLeadValues).forEach((key) => {
        if (key.includes('__contract__checkbox')) {
          //@ts-ignore
          delete newLeadValues[key]
        }
      })

      await leadMutation
        .mutateAsync(newLeadValues)
        .then((result: any) => {
          if (values.blackCarFund) {
            mutationPersonTagBlackCarFund.mutateAsync({
              personId: result.data.id ?? '',
              tagId: values.blackCarFund ?? '',
            })
          }
          setDescriptionModalWithError('The lead has been successfully created')
          setResult('success')
          setnewPersonId(result?.data?.id)
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
          setDescriptionModalWithError(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required('First Name is required'),
      lastName: yup.string().name().required('Last Name is required'),
      email: yup.string().email().required('Email is required'),
      phoneNumber: yupPhoneValidator({
        requiredMessage: 'Phone Number is required',
      }),
      abcIds: yup
        .array()
        .required('Associated Benefit Contract is required')
        .min(1, 'Associated Benefit Contract must have at least 1 items'),
      rating: yup.string().when({
        is: () => isRequiredRating,
        then: yup.string().required('Person MUST be rated'),
        otherwise: yup.string().notRequired(),
      }),
    }),
  })

  const { refetch: findPersonByEmail } = useGetPersons({
    organizationId: currentOrganizationId || '',
    email: formik.values.email,
    enabled: !!formik.values.email,
  })

  useEffect(() => {
    formik.validateForm() //TODO refactor and remove it
  }, [])

  const handleCloseResult = () => {
    if (result === 'success' && newPersonId) {
      router.push(`${PRIVATE_ROUTES.LEADS.path}/${newPersonId}`)
    } else {
      setResult(null)
    }
  }

  const handleChangeStep = async (nextStepIndex: number) => {
    if (currentStepIndex === 0 && nextStepIndex === 1) {
      const result = await findPersonByEmail()

      if (result.data?.pages?.[0].data?.records.length) {
        formik.setFieldError('email', 'Email already exists')

        return
      }
    }

    setCurrentStepIndex(nextStepIndex)
  }

  const CurrentStep =
    Object.entries(Steps).find(
      ([key, value]) => key === `${STEPS_ORDER[currentStepIndex]}Step`,
    )?.[1] ?? null
  const stepsCount = Object.keys(Steps).length

  if (!CurrentStep) {
    return null
  }

  return (
    <>
      <Form providerValue={formik}>
        <CurrentStep />

        <BottomButtons
          currentStepIndex={currentStepIndex}
          stepsCount={stepsCount}
          loading={leadMutation.isPending}
          onChangeStep={handleChangeStep}
        />
      </Form>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          onClose={handleCloseResult}
          description={descriptionModalWithError}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadForm,
  'is_enable_create_lead_form',
  true,
)
