import { Modal } from '@workers-benefit-fund/wbf-ui-components'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useBlocker, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { BenestreamPersonForm } from 'src/benestream/components'
import { PRIVATE_ROUTES } from 'src/benestream/config'
import {
  transformerPersonsResponseToReadable,
  transformerPersonsToRequest,
} from 'src/benestream/helpers'
import {
  useGetEligibilityGuidelineByStateAbbreviation,
  useGetPerson,
  useMutatePrimaryPerson,
} from 'src/benestream/hooks'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { SingleBenestreamPersonTemplate } from 'src/benestream/templates'
import {
  Breadcrumbs,
  Form,
  MetroPlus,
  PersonNotesBlock,
  Portal,
} from 'src/common/components'
import { PersonNoteModal } from 'src/common/components/person/PersonNotesBlock/components'
import { STATES } from 'src/common/config'
import { yupPhoneValidator } from 'src/common/helpers'
import { ModalResult } from 'src/default/components'
import { basicApiErrorHandling, getPersonDocuments } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './single-account-page.module.scss'

const SingleAccountPageWrapper = () => {
  const [beneStreamPersonData, setBeneStreamPersonData] = useState({})
  const { personId } = useParams<{ personId: string }>()
  const router = useRouter()

  const [isReady, setIsReady] = useState<boolean>(false)

  const { data, refetch: refetchPerson } = useGetPerson({ personId })
  const personData = data?.data

  const currentStateAbbreviation = Object.values(STATES).find(
    (item) =>
      item.value === personData?.state || item.match[0] === personData?.state,
  )?.match?.[0]

  const accountData = (() => {
    if (beneStreamPersonData) {
      return transformerPersonsResponseToReadable(
        beneStreamPersonData as IBenestreamPerson,
      )
    }
    return beneStreamPersonData
  })()

  const {
    data: eligibilityGuidelineData,
    refetch: refetchEligibilityGuideline,
  } = useGetEligibilityGuidelineByStateAbbreviation({
    stateAbbreviation: currentStateAbbreviation ?? undefined,
    personId,
  })

  const eligibilityGuideline = eligibilityGuidelineData?.data ?? null

  useEffect(() => {
    if (personData) {
      if (!personData.isAccount) {
        router.push(`${PRIVATE_ROUTES.LEADS.path}/${personId}`)
      } else {
        setIsReady(true)
        setBeneStreamPersonData(personData)
      }
    }
  }, [personData])

  if (!isReady) {
    return null
  }

  return (
    <>
      <SingleBenestreamPersonTemplate
        personType="account"
        state={personData?.state}
        eligibilityGuideline={eligibilityGuideline}
      >
        {beneStreamPersonData ? (
          <SingleAccountPage
            initialValues={accountData}
            refetchEligibilityGuideline={refetchEligibilityGuideline}
            refetchPerson={refetchPerson}
          />
        ) : null}
      </SingleBenestreamPersonTemplate>
    </>
  )
}

interface SingleAccountPageProps {
  initialValues: Partial<IBenestreamPerson>
  refetchEligibilityGuideline: () => void
  refetchPerson: () => void
}

const SingleAccountPage = (props: SingleAccountPageProps) => {
  const { initialValues, refetchEligibilityGuideline, refetchPerson } = props

  const [result, setResult] = useState<string | null>(null)

  const primaryPersonUpdateMutation = useMutatePrimaryPerson('update')

  const [isLeavingTheRecordWithoutNote, setIsLeavingTheRecordWithoutNote] =
    useState<boolean>(true)

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isLeavingTheRecordWithoutNote &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isLeavingTheRecordWithoutNote) {
        event.preventDefault()
      }
      return
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isLeavingTheRecordWithoutNote])

  const handleCloseResult = () => {
    setResult(null)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await primaryPersonUpdateMutation
        .mutateAsync(transformerPersonsToRequest(values))
        .then(() => {
          setResult('success')
          refetchPerson()
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required('First name is a required field'),
      lastName: yup.string().name().required('Last name is a required field'),
      email: yup.string().email().required('Email is a required field'),
      phoneNumber: yupPhoneValidator({
        requiredMessage: 'Phone number is a required field',
      }),
      birthDate: yup
        .string()
        .date({
          format: 'MM/DD/YYYY',
          specialRule: 'birthdate',
        })
        .required('Date of Birth is a required field'),
      preferredLanguage: yup
        .string()
        .required('Preferred language is a required field')
        .nullable(),
      householdSize: yup
        .number()
        .required('Household size is a required field'),
      agreedToPrivacyWaiver: yup
        .boolean()
        .isTrue('Accept Privacy Waiver')
        .required(),
    }),
  })

  useEffect(() => {
    formik.setValues(initialValues)
    if (initialValues.documents) {
      const documents = getPersonDocuments(initialValues.documents)

      formik.setFieldValue('tlcLicenseNumber', documents.tlc || '')
      formik.setFieldValue('driverLicense', documents.driverLicense || '')
    }

    if (initialValues.enrollments && initialValues.enrollments.length > 0) {
      formik.setFieldValue(
        'companyName',
        initialValues.enrollments[0].associatedBenefitContract.name ?? '',
      )
    }
  }, [initialValues])

  const personName = (() => {
    const { firstName, lastName } = formik.values
    if (!firstName && !lastName) {
      return 'Account'
    }

    return `${firstName} ${lastName}`
  })()

  useEffect(() => {
    formik.setFieldValue('otherEmail', initialValues.otherEmail)
  }, [initialValues.otherEmail])

  return (
    <>
      <Breadcrumbs items={[{ label: personName }]} />

      <div className={styles.wrapper}>
        <Form providerValue={formik} className={styles.wrapper}>
          <BenestreamPersonForm
            personType="account"
            refetchEligibilityGuideline={refetchEligibilityGuideline}
            refetchPerson={refetchPerson}
            onResult={setResult}
          />
        </Form>

        {result !== null ? (
          <ModalResult
            isSuccess={result.toLowerCase().includes('success')}
            description={result !== 'success' ? result : undefined}
            onClose={handleCloseResult}
            title={
              result !== 'success' && result.toLowerCase().includes('success')
                ? 'Household Member Deleted'
                : ''
            }
          />
        ) : null}
        <PersonNotesBlock
          setIsLeavingTheRecordWithoutNote={setIsLeavingTheRecordWithoutNote}
        />
      </div>

      {blocker.state === 'blocked' ? (
        <Modal isOpen onClose={() => blocker.reset()}>
          <PersonNoteModal onAfterSave={() => blocker.proceed()} />
        </Modal>
      ) : null}

      {formik.values.state === STATES.NEW_YORK.value ? (
        <Portal.Wrapper id="metroPlus" isAdaptive={false}>
          <MetroPlus personType="account" />
        </Portal.Wrapper>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountPageWrapper,
  'is_enable_single_account_view',
  true,
)
