import useDebounce from '@v2/commons/hooks/debounce/useDebounce'

import TextInputFilter, {
  type TextFilterProps,
} from './FilterTextInput.component'

interface TextFilterDebounceProps extends TextFilterProps {
  placeholder: string
  delay?: number
  debounceFunc: (event: string) => void
}

const FilterWithDebounce = ({
  placeholder,
  delay = 600,
  onChange,
  debounceFunc,
  ...props
}: TextFilterDebounceProps) => {
  const debounceFunction = useDebounce(debounceFunc, delay)

  return (
    <TextInputFilter
      placeholder={placeholder}
      {...props}
      onChange={onChange}
      debounceFunc={debounceFunction}
    />
  )
}
export default FilterWithDebounce
