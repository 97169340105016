import { IChat } from '@v2/commons/interface/chat'
import { User } from '@v2/domains/user/models/user.model'

import { IPerson } from 'src/common/interfaces'

import { IDocument } from './document.model'
import { IEnrollment } from './enrollment.model'
import { ITag } from './tags.model'

export interface IBasicEntity {
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}
export interface IUser {
  id: string
  firstName: string
  lastName: string
  hipaaCertified?: string
  username: string
  userPhoneNumber: string | null
  person: IPerson
  email: string
}

export enum EnumPersonStatus {
  NEW = 'New',
  PARTIAL = 'Partial',
  SIGNEDUP = 'Signed Up',
  PROCESSING = 'Processing',
  INVALIDLICENSE = 'Invalid License',
  NAMEMISMATCH = 'Name Mismatch',
  MISSINGINFORMATION = 'Missing Information',
  ELIGIBLE = 'Eligible',
  ELIGIBLEPARTIALLY = 'Eligible Partially',
  EXISTINGACCOUNT = 'Existing Account',
}

export enum EnumPersonLanguage {
  'en' = 'English',
  am = 'Amharic',
  ar = 'Arabic',
  km = 'Cambodian (Khmer)',
  zh = 'Chinese',
  fa = 'Farsi',
  ja = 'Japanese',
  ko = 'Korean',
  lo = 'Laotian',
  mh = 'Marshallese',
  om = 'Oromo',
  pa = 'Punjabi',
  ru = 'Russian',
  so = 'Somali',
  'es' = 'Spanish',
  uk = 'Ukrainian',
  vi = 'Vietnamese',
  'fr' = 'French',
  'hi' = 'Hindi',
  'bn' = 'Bengali',
  'Other' = 'Other',
}

//TODO-BENESTREAM mb it is not common
export enum EnumLeadStatusForConvering {
  ELIGIBLE = EnumPersonStatus.ELIGIBLE,
  EXISTINGACCOUNT = EnumPersonStatus.EXISTINGACCOUNT,
}

export interface IPersonSearchResultItem extends PersonModel {
  enrollments: IEnrollment[]
  documents: IDocument[]
  worksite: string
}

export interface IHistory {
  id: string
  person: PersonModel
  objectType: string
  objectId: string
  objectField: string
  originalValue: string
  newValue: string
  dateOfChange: string
  user: User
}
export interface PersonModel extends IBasicEntity {
  id: string
  email: string
  firstName: string
  lastName: string
  isVerified: boolean
  isAccount: boolean
  isEmailVerified: boolean
  birthDate: string | null
  companies: string[]
  gender?: string | null
  phoneNumber: string
  otherPhoneNumber: string[]
  drivingStates: string[]
  street: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  source: string | null
  driversLicenseNumber: string
  haveHealthInsurance: string | null
  coverageThroughTheState: string | null
  pay20PerMonth: string | null
  rating: string | null
  preferredLanguage: string
  otherPreferredLanguage: string | null
  country: string | null
  otherGender: string | null
  tlcLicenseNumber: string
  sfdcId: string | null
  otherEmail: string[]
  enrollments: IEnrollment[]
  documents: IDocument[]
  assignedTo: Omit<IUser, 'firstName' | 'lastName'>
  createdBy: IUser
  updatedBy: IUser
  chat: IChat
  tags: ITag[]
}

export interface PersonsFiltersModel {
  organizationId?: string
  fromDate?: string
  toDate?: string
  zipCode?: string
  city?: string
  preferredLanguage?: string
  isUnassigned?: string
  companies?: string
  limit?: number
  rating?: string
  offset?: number
  orderBy?: string
  enabled?: boolean
  isVerified?: boolean
  tags?: string[]
}
export interface SearchFiltersModel {
  organizationId?: string
  limit?: number
  offset?: number
  getUserOrganizations?: boolean
  search?: string
}
