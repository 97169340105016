import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LocalStorageUserProfile, User, UserProfile } from './user.model'

export const defaultUserProfile: UserProfile = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    username: '',
  },
  membership: {
    id: '',
    role: '',
    status: '',
    hasAPIKey: false,
    membershipRole: {
      id: '',
      name: '',
    },
  },
  organization: {
    id: '',
    name: '',
  },
  tokenPayload: {
    exp: 0,
    iat: 0,
    id: '',
    membershipId: '',
    organizationId: '',
    parentTokenId: '',
    sub: '',
    userId: '',
  },
  policies: [],
}

export const defaultUser: User = {
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  phone: '',
  hipaaCertified: false,
  aircallUserId: '',
  membership: {
    id: '',
    role: '',
    hasAPIKey: false,
    status: '',
  },
  emailDomains: [],
}

const initialState = {
  userProfile: { ...defaultUserProfile },
  user: { ...defaultUser },
}

const userStore = createSlice({
  name: 'user',
  initialState: {
    userProfile: {
      ...(localStorage.getItem(LocalStorageUserProfile)
        ? (JSON.parse(localStorage.getItem(LocalStorageUserProfile) as string)
            ?.userProfile as UserProfile)
        : initialState.userProfile),
    },
    user: { ...initialState.user },
  },
  reducers: {
    setUserProfile: (state, action: PayloadAction<Partial<UserProfile>>) => {
      state.userProfile = {
        ...state.userProfile,
        ...action.payload,
      }

      localStorage.setItem(LocalStorageUserProfile, JSON.stringify(state))
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = {
        ...action.payload,
      }
    },
    resetUser: (state) => {
      state.user = defaultUser
    },
    setUserInformation: (
      state,
      action: PayloadAction<
        Pick<
          User,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'phone'
          | 'username'
          | 'aircallUserId'
          | 'hipaaCertified'
        >
      >,
    ) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
    resetUserProfileStore: (state) => {
      state = { ...initialState }
      localStorage.setItem(LocalStorageUserProfile, '')
    },
  },
})

export const {
  setUserProfile,
  setUser,
  resetUserProfileStore,
  setUserInformation,
  resetUser,
} = userStore.actions
export default userStore.reducer
