import {
  UseFormReturnType,
  hasLength,
  isEmail,
  isNotEmpty,
  useForm,
} from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getErrorMessages } from 'src/v2/commons/utils/getError'

import { useGetUserQuery, useUpdateUserMutation } from '../models/user.api'
import { CreateUser } from '../models/user.model'

interface UseUserInformationUseCase {
  onSubmit: () => void
  editForm: boolean
  setEditForm: (state: boolean) => void
  handleCancelForm: () => void
  userInformationForm: UseFormReturnType<CreateUser>
  errorModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  loading: boolean
}

const useUserInformationUseCase = (): UseUserInformationUseCase => {
  const { userId = '' } = useParams()
  const [opened, { open, close }] = useDisclosure(false)
  const [editForm, setEditForm] = useState<boolean>(false)
  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const { data: user, isSuccess } = useGetUserQuery(userId)

  const userInformationForm = useForm<CreateUser>({
    mode: 'uncontrolled',
    initialValues: {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      phone: '',
      aircallUserId: '',
      hipaaCertified: false,
      password: '',
      organizationId: '',
    },
    validateInputOnBlur: true,
    validate: {
      firstName: isNotEmpty('First Name is required!'),
      lastName: isNotEmpty('Last Name is required!'),
      username: isEmail('Invalid Email'),
      password: hasLength({ min: 8 }, 'At least 8 characters!'),
      phone: hasLength({ min: 10 }, 'At least 10 characters!'),
    },
  })

  useEffect(() => {
    if (isSuccess) {
      userInformationForm.setValues({
        id: user.id ?? '',
        username: user.username ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        phone: user.phone ?? '',
        aircallUserId: user.aircallUserId ?? '',
        hipaaCertified: user.hipaaCertified ?? false,
        password: '',
        type: user?.type ?? 'regular',
      })
    }
  }, [user, isSuccess])

  const onSubmit = () => {
    userInformationForm.onSubmit(async (values) => {
      updateUser(values)
        .unwrap()
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'User succesfuly changed',
          })
          setEditForm(false)
        })
        .catch((error) => {
          userInformationForm.setErrors({
            error: getErrorMessages(error?.data?.message ?? ''),
          })
          open()
        })
    })()
  }

  const handleCancelForm = () => {
    userInformationForm.setValues({
      id: user?.id ?? '',
      username: user?.username ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phone: user?.phone ?? '',
      aircallUserId: user?.aircallUserId ?? '',
      hipaaCertified: user?.hipaaCertified ?? false,
      password: '',
      type: user?.type ?? 'regular',
    })
    setEditForm(false)
  }

  return {
    onSubmit,
    editForm,
    setEditForm,
    handleCancelForm,
    userInformationForm,
    errorModal: {
      opened,
      open,
      close,
    },
    loading: isLoading,
  }
}

export default useUserInformationUseCase
