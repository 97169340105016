import { yup } from 'src/common/libs'

interface IYupPhoneValidatorProps {
  requiredMessage?: string
}

export const yupPhoneValidator = (
  props: IYupPhoneValidatorProps | undefined = {},
) => {
  const { requiredMessage = 'is required' } = props

  return yup
    .string()
    .phone()
    .required(requiredMessage)
    .test(
      'should-not-start-with-one',
      'Phone numbers cannot start with 1',
      (value) => (!value ? true : !value.startsWith('+11')),
    )
}
