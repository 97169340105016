import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  Loader,
  Select,
  Text,
  TextInput,
} from '@mantine/core'
import DeleteConfirmationModal from '@v2/commons/components/DeleteConfirmationModal/DeleteConfirmationModal.component'
import SuccessErrorModal from '@v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'

import { ActionMenu, Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import HtmlPreview from 'src/v2/commons/components/HtmlPreview/HtmlPreview'
import RichText from 'src/v2/commons/components/RichText/RichText'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import getEmailTemplateContainer from '../../containers/getEmailTemplate.container'
import styles from './emailTemplate.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Email Template', href: '/' },
]

const OPTION_TYPE_EMAIL = [
  { value: 'PUBLIC', label: 'Public' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'TRANSACTIONAL', label: 'Transactional' },
]

const EmailTemplateView = () => {
  const {
    isLoading,
    isEditEmailTemplate,
    isDeleteEmailTemplate,
    setIsEditEmailTemplate,
    setIsDeleteEmailTemplate,
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    handleViewEmailTemplates,
    handleDeleteEmailTemplate,
  } = getEmailTemplateContainer()

  return (
    <BasicTemplate title="Email Template" breadCrumbItems={breadCrumbItems}>
      <Card shadow="lg" radius="md" style={{ padding: '26px' }}>
        <Box>
          <Flex align="center" mt="lg">
            <Text mt={20} flex={1} className={styles.title}>
              Template Information
            </Text>
            <ActionMenu
              items={[
                {
                  label: 'Update',
                  color: 'blue',
                  icon: <Icons.Edit />,
                  onClick: () => setIsEditEmailTemplate(true),
                  enable: true,
                },
                {
                  label: 'Delete',
                  color: 'red',
                  icon: <Icons.Trash />,
                  onClick: () => setIsDeleteEmailTemplate(true),
                  enable: true,
                },
              ]}
            />
          </Flex>
          {isLoading ? (
            <Center>
              <Loader size={30} />
            </Center>
          ) : (
            <>
              <Flex gap="xl">
                <TextInput
                  readOnly={!isEditEmailTemplate}
                  label="Subject"
                  style={{ flex: 0.9 }}
                  required
                  classNames={{
                    root: !isEditEmailTemplate ? styles.readOnlyField : '',
                  }}
                  {...emailTemplateForm.getInputProps('subject')}
                />
                <Select
                  readOnly={!isEditEmailTemplate}
                  required
                  style={{ flex: 0.3 }}
                  label="Type"
                  classNames={{
                    root: !isEditEmailTemplate ? styles.readOnlyField : '',
                  }}
                  {...emailTemplateForm.getInputProps('type')}
                  data={[...OPTION_TYPE_EMAIL]}
                />
              </Flex>
              <Flex gap="xl" mt="lg">
                <TextInput
                  readOnly={!isEditEmailTemplate}
                  required
                  label="Description"
                  style={{ flex: 1 }}
                  classNames={{
                    root: !isEditEmailTemplate ? styles.readOnlyField : '',
                  }}
                  {...emailTemplateForm.getInputProps('description')}
                />
              </Flex>
              <Flex gap="xl" mt="lg">
                <Box flex={1}>
                  <Text size="sm">Message</Text>
                  {isEditEmailTemplate ? (
                    <RichText
                      readOnly={!isEditEmailTemplate}
                      className={styles.wrapper}
                      value={emailTemplateForm.getValues().body}
                      onChange={setEmailBody}
                      placeholder="Your Message..."
                      showAttachments={false}
                      showHtmlTextButton={true}
                    />
                  ) : (
                    <HtmlPreview
                      content={emailTemplateForm.getValues().body}
                      className={styles.wrapper}
                    />
                  )}
                </Box>
              </Flex>
              <Group mt="xl" justify="space-between">
                <Button variant="default" onClick={handleViewEmailTemplates}>
                  Cancel
                </Button>
                {isEditEmailTemplate && (
                  <Button variant="filled" onClick={onSubmit}>
                    Save
                  </Button>
                )}
              </Group>
            </>
          )}
        </Box>
      </Card>
      <DeleteConfirmationModal
        subtitle="Are you sure you want to delete this template?"
        close={() => setIsDeleteEmailTemplate(false)}
        title="Confirm Template Removal"
        body="This action cannot be undone."
        opened={isDeleteEmailTemplate}
        buttonText="Delete Template"
        handleDelete={handleDeleteEmailTemplate}
      />
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EmailTemplateView,
  'is_get_roles_enabled',
  true,
)
