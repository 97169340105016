import CreateEmailTemplateView from '@v2/domains/email-template/views/CreateEmailTemplate/CreateEmailTemplate.view'
import EmailTemplateView from '@v2/domains/email-template/views/EmailTemplate/EmailTemplate.view'
import EmailTemplatesView from '@v2/domains/email-template/views/EmailTemplates/EmailTemplates.view'
import PersonMergingToolView from '@v2/domains/merging-tool/views/PersonMergingTool.view'
import AccountsPage from '@v2/domains/person/views/accounts/AccountsTable.view'
import SearchResultsPage from '@v2/domains/person/views/search/SearchResult.view'
import TicketsPage from '@v2/domains/ticket/views/Tickets.view'
import { RouteObject } from 'react-router-dom'

import AdministrationLayout from 'src/default/layouts/AdministrationLayout'
import EmailDomainsLayout from 'src/default/layouts/EmailDomainsLayout'
import FormLayout from 'src/default/layouts/FormLayout'
import SmsCampaignsPageLayout from 'src/default/layouts/SmsCampaignsPageLayout'
import UsersPageLayout from 'src/default/layouts/UsersPageLayout'
import SingleAccountPage from 'src/default/pages/Accounts/SingleAccountPage'
import SingleAccountPageTickets from 'src/default/pages/Accounts/SingleAccountPage/SingleAccountPageTickets/SingleAccountPageTickets'
import ABCsAdministrationPage from 'src/default/pages/Administration/ABCsAdministration/ABCsAdministrationPage/ABCsAdministrationPage'
import BenefitsAdministrationPage from 'src/default/pages/Administration/BenefitsAdministration/BenefitsAdministrationPage'
import MutateBenefitsAdministrationPage from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage'
import AdministrationBenefitsCreate from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage'
import DataLoaderPage from 'src/default/pages/DataLoader/DataLoaderPage'
import CreateEmailDomainPage from 'src/default/pages/EmailDomains/CreateEmailDomainPage'
import EmailDomainsPage from 'src/default/pages/EmailDomains/EmailDomainsPage'
import SingleEmailDomainPage from 'src/default/pages/EmailDomains/SingleEmailDomainPage'
import CreateFormPage from 'src/default/pages/Forms/CreateFormPage'
import FormsPage from 'src/default/pages/Forms/FormsPage'
import SingleFormPage from 'src/default/pages/Forms/SingleFormPage'
import SingleFormSubmission from 'src/default/pages/Forms/SingleFormSubmission'
import FormSubmissionsPage from 'src/default/pages/Forms/SubmissionsPage'
import CreateLeadPage from 'src/default/pages/Leads/CreateLeadPage'
import LeadTicketsPersonPage from 'src/default/pages/Leads/LeadTicketsPersonPage/LeadTicketsPersonPage'
import SingleLeadPage from 'src/default/pages/Leads/SingleLeadPage'
import SmsCampaignDetail from 'src/default/pages/Sms/SmsCampaignDetail'
import SmsCampaignsPage from 'src/default/pages/Sms/SmsCampaigns'
import TasksPages from 'src/default/pages/Tasks/TasksPages/TasksPages'
import CreateUserPage from 'src/default/pages/Users/CreateUserPage'
import SingleUserPage from 'src/default/pages/Users/SingleUserPage'
import UsersPage from 'src/default/pages/Users/UsersPage'
import AdministrationBenefitsTemplatePreview from 'src/default/templates/AdministrationBenefitsTemplatePreview/AdministrationBenefitsTemplatePreview'
import AdministrationView from 'src/v2/domains/administration/views/Administration.view'
import DataProcessingPage from 'src/v2/domains/data-process/view/DataProcess'
import LeadsPage from 'src/v2/domains/person/views/leads/LeadsTable.view'
import PoliciesView from 'src/v2/domains/policy/views/Policies.view'
import ReportsPage from 'src/v2/domains/report/views/Reports'
import RoleView from 'src/v2/domains/role/views/Role.view'
import RolesView from 'src/v2/domains/role/views/Roles.view'
import CreateUserView from 'src/v2/domains/user/views/CreateUserView'
import SingleUserView from 'src/v2/domains/user/views/SingleUserView'
import UsersAdministrationPage from 'src/v2/domains/user/views/UsersView'

import DashboardPage from '../default/pages/Dashboard/Dashboard'
import CreateSmsCampaignView from '../v2/domains/smsCampaigns/views/CreateSmsCampaignView/CreateSmsCampaignView'

export const defaultRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: <DashboardPage />,
  },
  {
    path: 'leads',
    element: <LeadsPage />,
  },
  {
    path: 'leads/:personId',
    element: <SingleLeadPage />,
  },
  {
    path: 'leads/:personId/:ticketId',
    element: <LeadTicketsPersonPage />,
  },
  {
    path: 'leads/create',
    element: <CreateLeadPage />,
  },
  {
    path: 'accounts',
    element: <AccountsPage />,
  },
  {
    path: 'accounts/:personId',
    element: <SingleAccountPage />,
  },
  {
    path: 'accounts/:personId/:ticketId',
    element: <SingleAccountPageTickets />,
  },
  {
    path: 'merging-tool',
    element: <PersonMergingToolView />,
  },
  {
    element: <AdministrationLayout />,
    children: [
      {
        path: 'administration',
        element: <AdministrationView />,
      },
      {
        path: 'administration/abcs',
        element: <ABCsAdministrationPage />,
      },
      {
        path: 'administration-benefits',
        element: <BenefitsAdministrationPage />,
      },
      {
        path: 'administration-benefits/_preview',
        element: <AdministrationBenefitsTemplatePreview />,
      },
      {
        path: 'administration-benefits/:benefitId',
        element: <MutateBenefitsAdministrationPage />,
      },
      {
        path: 'administration-benefits/create',
        element: <AdministrationBenefitsCreate />,
      },
      {
        path: 'administration/policies',
        element: <PoliciesView />,
      },
      {
        path: 'administration/roles',
        element: <RolesView />,
      },
      {
        path: 'administration/roles/:roleId',
        element: <RoleView />,
      },
      {
        path: 'administration/users',
        element: <UsersAdministrationPage />,
      },
      {
        path: 'administration/users/create',
        element: <CreateUserView />,
      },
      {
        path: 'administration/users/:userId',
        element: <SingleUserView />,
      },
      {
        path: 'administration/email-template',
        element: <EmailTemplatesView />,
      },
      {
        path: 'administration/email-template/:emailTemplateId',
        element: <EmailTemplateView />,
      },
      {
        path: 'administration/email-template/create',
        element: <CreateEmailTemplateView />,
      },
    ],
  },
  {
    element: <EmailDomainsLayout />,
    children: [
      {
        path: 'email-domains',
        element: <EmailDomainsPage />,
      },
      {
        path: ':emailDomainId',
        element: <SingleEmailDomainPage />,
      },
      {
        path: 'create',
        element: <CreateEmailDomainPage />,
      },
    ],
  },
  {
    path: 'form-submissions',
    element: <FormSubmissionsPage />,
  },
  {
    path: 'form-submissions/:formId',
    element: <SingleFormSubmission />,
  },
  {
    path: 'reports',
    element: <ReportsPage />,
  },
  {
    path: 'data-processing',
    element: <DataProcessingPage />,
  },
  {
    path: 'search/:searchValue',
    element: <SearchResultsPage />,
  },
  {
    element: <SmsCampaignsPageLayout />,
    children: [
      {
        path: 'sms-campaigns',
        element: <SmsCampaignsPage />,
      },
      {
        path: 'sms-campaigns/create',
        element: <CreateSmsCampaignView />,
      },
      {
        path: 'sms-campaigns/:campaignId',
        element: <SmsCampaignDetail />,
      },
    ],
  },
  {
    path: 'tasks',
    element: <TasksPages />,
  },
  {
    path: 'tickets',
    element: <TicketsPage />,
  },
  {
    path: 'upload-file',
    element: <DataLoaderPage />,
  },
  {
    element: <UsersPageLayout />,
    children: [
      {
        path: 'users',
        element: <UsersPage />,
      },
      {
        path: 'users/create',
        element: <CreateUserPage />,
      },
      {
        path: 'users/:userId',
        element: <SingleUserPage />,
      },
    ],
  },
  {
    element: <FormLayout />,
    children: [
      {
        path: 'form-templates',
        element: <FormsPage />,
      },
      {
        path: 'form-templates/create',
        element: <CreateFormPage />,
      },
      {
        path: 'form-templates/:formTemplateId',
        element: <SingleFormPage />,
      },
    ],
  },
]
