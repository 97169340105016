import { Button, Flex, Group, Text } from '@mantine/core'
import { DatesRangeValue } from '@mantine/dates'
import { UseFormReturnType } from '@mantine/form/lib/types'
import DateRangePickerFilter from '@v2/commons/components/DatePickerFilter/DateRangeFilterPicker.component'
import ButtonFilter from '@v2/commons/components/FilterButton/FilterButton.component'
import SelectFilter from '@v2/commons/components/FilterSelect/FilterSelect.component'
import FilterTextDebounce from '@v2/commons/components/FilterTextInput/FilterTextDebounce.component.tsx'
import SelectLanguageFilter from '@v2/commons/components/LanguageFilterSelect/LanguageSelect.component'
import MultiSelectFilter from '@v2/commons/components/MultiSelect/MultiSelect.component'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import PolicyCheckHOC from 'src/v2/commons/HOC/policyCheckHOC'
import { type PersonsFiltersModel } from 'src/v2/domains/person/models/person.model'

import classes from './persons-filters.module.scss'

interface PersonTableFilterProps {
  personsFiltersForm: UseFormReturnType<PersonsFiltersModel>
  handleDateRangeChange: (value: DatesRangeValue) => void
  clearAllFilters: () => void
  isProgrammaticFormUpdateRef: React.MutableRefObject<boolean>
  handleClearStorePerson: () => void
  totalRecords: number
  personTags: string[]
}

const PersonTableFilter = ({
  personsFiltersForm,
  handleDateRangeChange,
  clearAllFilters,
  isProgrammaticFormUpdateRef,
  handleClearStorePerson,
  totalRecords,
  personTags,
}: PersonTableFilterProps) => {
  const [key, setFormKey] = useState(1)
  const [openFilters, setOpenFilters] = useState(false)
  const { city: initialCity = '', zipCode: initialzipCode = '' } =
    personsFiltersForm.getValues()
  const [city, setCity] = useState(initialCity)
  const [zipCode, setZipCode] = useState(initialzipCode)

  const handleClearFilters = () => {
    setFormKey((prev) => ++prev)
    handleClearStorePerson()
    clearAllFilters()
    setCity('')
    setZipCode('')
  }

  const handleChangeValue = (
    path: keyof PersonsFiltersModel,
    value: PersonsFiltersModel[keyof PersonsFiltersModel],
  ) => {
    personsFiltersForm.setFieldValue(path, value)
    if (isProgrammaticFormUpdateRef.current) {
      isProgrammaticFormUpdateRef.current = false
    } else {
      handleClearStorePerson()
    }
  }
  const toggleOpenFilters = () => {
    setOpenFilters((prev) => !prev)
  }

  useEffect(() => {
    const persistedFilters = personsFiltersForm.getValues()
    const isPersistedFilters = Object.keys(persistedFilters).length
    if (isPersistedFilters) {
      setOpenFilters(true)
      if (persistedFilters.city) {
        setCity(persistedFilters.city)
      }
      if (persistedFilters.zipCode) {
        setZipCode(persistedFilters.zipCode)
      }
    }
  }, [])

  const getDefaultDatevalues = (): [Date, Date] | undefined => {
    const filters = personsFiltersForm.getValues()
    const fromDate = filters['fromDate']
    const toDate = filters['toDate']
    if (fromDate && toDate) {
      return [dayjs(fromDate).toDate(), dayjs(toDate).toDate()]
    }
    return undefined
  }
  return (
    <>
      <Flex justify="space-between" align="center" mb="30px">
        <ButtonFilter onClick={toggleOpenFilters} isOpen={openFilters} />
        <Text lh="26px" size="18px">
          Total Records: {totalRecords}
        </Text>
      </Flex>
      {openFilters && (
        <form key={key} style={{ marginBottom: '30px' }}>
          <Group
            preventGrowOverflow={false}
            grow={true}
            classNames={{ root: classes['group-root'] }}
          >
            <SelectLanguageFilter
              placeholder="Preferred Language"
              {...personsFiltersForm.getInputProps('preferredLanguage')}
              key={personsFiltersForm.key('preferredLanguage')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('preferredLanguage', value)
              }}
            />
            <PolicyCheckHOC policyName="is_rating_enabled">
              <SelectFilter
                options={[
                  { label: 'Unrated', value: 'Unrated' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                ]}
                placeholder="Person Rating"
                key={personsFiltersForm.key('Person Rating')}
                {...personsFiltersForm.getInputProps('rating')}
                onChange={(
                  value: PersonsFiltersModel[keyof PersonsFiltersModel],
                ) => {
                  handleChangeValue('rating', value)
                }}
              />
            </PolicyCheckHOC>
            <FilterTextDebounce
              placeholder="City"
              value={city}
              key={personsFiltersForm.key('city')}
              onChange={(event: string) => {
                setCity(event)
              }}
              debounceFunc={(value) => handleChangeValue('city', value)}
            />
            <FilterTextDebounce
              placeholder="Postal Code"
              key={personsFiltersForm.key('zipCode')}
              value={zipCode}
              onChange={(event: string) => {
                setZipCode(event)
              }}
              debounceFunc={(value) => handleChangeValue('zipCode', value)}
            />
            <SelectFilter
              options={[
                { label: 'Uber', value: 'Uber' },
                { label: 'Lyft', value: 'Lyft' },
                { label: 'Other', value: 'Other' },
              ]}
              placeholder="Company"
              {...personsFiltersForm.getInputProps('companies')}
              key={personsFiltersForm.key('companies')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('companies', value)
              }}
            />
            <SelectFilter
              options={[
                { label: 'Yes', value: 'false' },
                { label: 'No', value: 'true' },
              ]}
              placeholder="Assigned"
              {...personsFiltersForm.getInputProps('isUnassigned')}
              key={personsFiltersForm.key('isUnassigned')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('isUnassigned', value)
              }}
            />
            <DateRangePickerFilter
              valueFormat="MM/DD/YYYY"
              placeholder="From - To"
              handleDateRangeChange={handleDateRangeChange}
              getFilters={getDefaultDatevalues}
              key={personsFiltersForm.key('From - To')}
            />
            <MultiSelectFilter
              data={personTags}
              {...personsFiltersForm.getInputProps('tags')}
              placeholder="Tags"
              areSelected={!!personsFiltersForm.getValues()['tags']?.length}
              key={personsFiltersForm.key('tags')}
              onChange={(value: string[]) => handleChangeValue('tags', value)}
              searchable={true}
              clearable={true}
              nothingFoundMessage="Nothing found..."
            />
            <Flex miw="50%" justify="flex-end">
              <Button
                variant="transparent"
                classNames={{ root: classes['clear-button-root'] }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear All
              </Button>
            </Flex>
          </Group>
        </form>
      )}
    </>
  )
}

export default PersonTableFilter
