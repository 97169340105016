import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query'

import { rootApi } from 'src/v2/store/api/rootApi'

import { type UsersResponseModel } from './user.model'
import { CreateUser, IUsersFilter, User, UserMembership } from './user.model'

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      UsersResponseModel,
      IUsersFilter & { organizationId: string }
    >({
      query: (queryArgs) => ({
        url: '/v4/users',
        method: 'GET',
        params: {
          status: queryArgs.status,
          limit: queryArgs.limit,
          offset: queryArgs.offset,
          search: queryArgs.search,
          orderBy: queryArgs.orderBy,
          userType: queryArgs.userType,
        },
      }),
      providesTags: ['USERS'],
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...rest } = queryArgs
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: rest,
        })
      },
      merge: (currentCache, newItems) => {
        return {
          ...newItems,
          records: [...(currentCache?.records || []), ...newItems.records],
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.offset && currentArg?.offset !== previousArg?.offset
        )
      },
    }),
    getUser: builder.query<User, User['id']>({
      query: (id) => ({
        url: `/v3/users/${id}`,
        method: 'GET',
      }),
      providesTags: ['GET_USER'],
    }),
    updateUser: builder.mutation<
      Pick<
        User,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'username'
        | 'aircallUserId'
        | 'hipaaCertified'
      >,
      Pick<
        User,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'username'
        | 'aircallUserId'
        | 'hipaaCertified'
        | 'password'
      >
    >({
      query: (data) => {
        const {
          id,
          firstName,
          lastName,
          phone,
          username,
          aircallUserId,
          password,
        } = data
        return {
          url: `/users/${id}`,
          method: 'PATCH',
          data: {
            firstName,
            lastName,
            phone,
            email: username,
            aircallUserId: aircallUserId === '' ? undefined : aircallUserId,
            password: password === '' ? undefined : password,
          },
        }
      },
      invalidatesTags: ['GET_USER', 'USERS'],
    }),
    updateUserMembership: builder.mutation<null, Partial<UserMembership>>({
      query: (data) => {
        const { id, role, status } = data
        return {
          url: `/users-membership/${id}`,
          method: 'PATCH',
          data: { role, status },
        }
      },
      invalidatesTags: ['GET_USER'],
    }),
    deleteUserMemberShip: builder.mutation<{}, UserMembership['id']>({
      query: (membershipId) => ({
        url: `/users-membership/remove/${membershipId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['USERS'],
    }),
    useGenerateApiKey: builder.mutation<{}, UserMembership['id']>({
      query: (membershipId) => ({
        url: `/v3/auth/generate-api-key`,
        data: { membershipId: membershipId },
        method: 'POST',
      }),
      invalidatesTags: ['USERS'],
    }),
    createUser: builder.mutation<Pick<User, 'id'>, CreateUser>({
      query: (data) => {
        const {
          firstName,
          lastName,
          phone,
          username,
          aircallUserId,
          password,
          organizationId,
          type,
        } = data
        return {
          url: `/users/`,
          method: 'POST',
          data: {
            firstName,
            lastName,
            phone,
            email: username,
            aircallUserId: aircallUserId === '' ? undefined : aircallUserId,
            password: password === '' ? undefined : password,
            organizationId,
            type,
          },
        }
      },
      invalidatesTags: ['USERS'],
    }),
    deleteUser: builder.mutation<{ ok: boolean }, User['id']>({
      query: (userId) => ({
        url: `/users/permanent-delete/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['USERS'],
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserMembershipMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useDeleteUserMemberShipMutation,
  useUseGenerateApiKeyMutation,
} = userApi
