import { rootApi } from 'src/v2/store/api/rootApi'

import {
  EmailTemplateResponse,
  IEmailTemplate,
  IEmailTemplateFilters,
  IEmailTemplateUpdate,
} from './emailTemplate.model'

export const emailTemaplteApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplates: builder.query<
      EmailTemplateResponse,
      { params: IEmailTemplateFilters }
    >({
      query: (queryArgs) => ({
        url: '/email-template',
        method: 'GET',
        params: queryArgs.params,
      }),
      providesTags: ['GET_EMAIL_TEMPLATES'],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { offset, ...rest } = queryArgs.params
        return JSON.stringify(rest)
      },
      merge: (
        currentCache,
        newItems,
        { arg: { params: { offset = 0 } = {} } = {} },
      ) => {
        if (offset === 0) {
          return newItems
        }
        const uniqueRecords = [...currentCache.records]
        newItems.records.forEach((newRecord) => {
          if (!uniqueRecords.some((record) => record.id === newRecord.id)) {
            uniqueRecords.push(newRecord)
          }
        })
        return {
          ...newItems,
          records: uniqueRecords,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.params.offset !== previousArg?.params.offset
      },
    }),
    getEmailTemplate: builder.query<IEmailTemplate, IEmailTemplate['id']>({
      query: (id) => ({
        url: `/email-template/${id}`,
        method: 'GET',
      }),
      providesTags: ['EMAIL_TEMPLATE_BY_ID'],
    }),
    createEmailTemplate: builder.mutation<
      IEmailTemplate,
      Omit<IEmailTemplate, 'id'>
    >({
      query: (data) => ({
        url: `/email-template/`,
        method: 'POST',
        data: { ...data },
      }),
      invalidatesTags: ['GET_EMAIL_TEMPLATES', 'EMAIL_TEMPLATE_BY_ID'],
    }),
    updateEmailTemplate: builder.mutation<IEmailTemplate, IEmailTemplateUpdate>(
      {
        query(data) {
          const { id, description, body, subject, type } = data
          return {
            url: `/email-template/${id}`,
            method: 'PATCH',
            data: { description, body, subject, type },
          }
        },
        invalidatesTags: ['GET_EMAIL_TEMPLATES', 'EMAIL_TEMPLATE_BY_ID'],
      },
    ),
    deleteEmailTemplate: builder.mutation<void, IEmailTemplate['id']>({
      query: (id) => ({
        url: `/email-template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GET_EMAIL_TEMPLATES', 'EMAIL_TEMPLATE_BY_ID'],
    }),
  }),
})

export const {
  useGetEmailTemplatesQuery,
  useGetEmailTemplateQuery,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
} = emailTemaplteApi
