import {
  Input,
  InputDateAndAge,
  InputPhone,
  InputTLCNumber,
  Select,
  SelectGender,
  SelectLanguage,
} from 'src/common/components'
import {
  MultiSelectCarCompanies,
  MultiSelectContracts,
} from 'src/default/components'
import {
  useGetCatalogABCsByOrganizationId,
  useGetTagsBlackCarFund,
} from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../../../v2/store/hooks'
import CreateLeadHealthcare from './CreateLeadHealthcare'
import styles from './main-step.module.scss'

const MainStep = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const isTLCRequired = userProfile.organization.profile?.isTLCRequired

  const catalogContracts = useGetCatalogABCsByOrganizationId({
    organizationId: currentOrganizationId ?? '',
  })

  const isRequiredRating = userProfile.policies.some(
    (policy) => policy === 'is_rating_enabled',
  )

  const options =
    (catalogContracts?.data?.data?.records ?? []).map(
      (abc: { id: string; name: string }) => ({
        label: abc.name || '',
        value: abc.id || '',
      }),
    ) ?? []

  const { data: { data: blackCarFundList } = { data: [] } } =
    useGetTagsBlackCarFund()

  return (
    <>
      <div className={styles.wrapper}>
        <Input
          required
          name="firstName"
          label="First Name"
          placeholder="Enter First Name"
          width="33"
          tabletWidth="50"
        />

        <Input
          required
          name="lastName"
          label="Last Name"
          placeholder="Enter Last Name"
          width="33"
          tabletWidth="50"
        />

        <SelectGender
          name="gender"
          label="Gender"
          placeholder="Select Gender"
          tabletWidth="31"
        />

        <Input
          required
          name="email"
          label="Email"
          placeholder="Enter Email Address"
          width="33"
          tabletWidth="50"
        />

        <InputPhone
          required
          isCreating
          name="phoneNumber"
          label="Main Mobile Phone"
          width="33"
          tabletWidth="50"
        />

        <InputPhone
          isCreating
          name="otherPhoneNumber"
          label="Secondary Mobile Phone"
          tabletWidth="50"
          width="33"
        />

        <InputDateAndAge
          name="birthDate"
          label="Birthdate"
          width="33"
          tabletWidth="50"
          format="MM/DD/YYYY"
        />

        <SelectLanguage
          name="preferredLanguage"
          label="Preferred Language"
          placeholder="Select Preferred Language"
          width="33"
          tabletWidth="50"
        />

        <Input
          name="driversLicenseNumber"
          label="License Number"
          placeholder="Enter License Number"
          width="33"
          tabletWidth="50"
        />

        <PolicyCheckHOC policyName="is_enable_create_lead_tlc_required">
          {isTLCRequired ? (
            <InputTLCNumber
              name="tlcLicenseNumber"
              width="33"
              tabletWidth="50"
            />
          ) : (
            <div />
          )}
        </PolicyCheckHOC>

        <MultiSelectContracts
          required
          name="abcIds"
          label="Associated Benefit Contracts"
          placeholder="Select Associated Benefit Contracts"
          width="33"
          tabletWidth="50"
          options={options}
        />

        <MultiSelectCarCompanies
          name="companies"
          label="Which Company(s) do you work for?"
          width="33"
          tabletWidth="50"
        />

        <PolicyCheckHOC policyName="is_rating_enabled">
          <Select
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
            ]}
            name="rating"
            label="Person Rating"
            tabletWidth="50"
            width="33"
            required={isRequiredRating}
          />
        </PolicyCheckHOC>

        <PolicyCheckHOC policyName="is_get_black_car_fund_tags_enabled">
          <Select
            options={blackCarFundList.map((blackCarFund: any) => ({
              label: blackCarFund.name,
              value: blackCarFund.id,
            }))}
            name="blackCarFund"
            label="Black Car Member Bases"
            tabletWidth="50"
            width="33"
          />
        </PolicyCheckHOC>
      </div>

      <PolicyCheckHOC
        policyName="is_enable_create_healthcare"
        disablePolicyCheck={true}
      >
        <CreateLeadHealthcare />
      </PolicyCheckHOC>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MainStep,
  'is_enable_create_lead_main_step',
  true,
)
