import dayjs from 'dayjs'
import type { FormikContextType } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { BasicBlock, Button, Form, Portal, Tabs } from 'src/common/components'
import { STATES } from 'src/common/config'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { capitalize, validateAndFormatPhoneNumber } from 'src/common/helpers'
import { IPersonMutationData } from 'src/common/interfaces'
import { useNotifications } from 'src/common/notification'
import {
  EmailSenderAndManagerEmailTemplate,
  MultiSelectTags,
} from 'src/default/components'
import { IEligibility, IEnrollment } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../v2/commons/HOC/policyCheckHOC'
import { setNewCommunication } from '../../../v2/domains/communication/models/communication.store'
import { setDeactivationCase } from '../../../v2/domains/deactivationCase/models/deactivationCase.store'
import { setShowTicketBlockInformation } from '../../../v2/domains/ticket/models/ticket.store'
import { useAppDispatch, useAppSelector } from '../../../v2/store/hooks'
import { TagDto } from '../../hooks/person/useGetPersonTags'
import DeactivationBenefitCase from './DeactivationBenefitCase'
import DeactivationCases from './DeactivationCases'
import DeletePersonModal from './DeletePersonModal/DeletePersonModal'
import DriverFormHeader from './DriverFormHeader'
import MainInformation from './MainInformation'
import SuccessDeletePersonModal from './SuccessDeleteModal/SuccessDeletePersonModal'
import { BasicInformation, LeadInformation } from './TabsContent'
import TicketInformation from './TicketInformation'
import styles from './driver-form.module.scss'

interface DriverFormProps<T> {
  customerType: 'lead' | 'account'
  isSuccess?: boolean
  data: any //TODO make it real
  formik: FormikContextType<T>
  onPreload?: () => void | Promise<any>
  onReady?: () => void
  onResult?: (result: string | null) => void
  onTagsUpdate?: (value: any) => void
  initialTags?: TagDto[]
  organizationTags?: TagDto[]
  isTagsLoading?: boolean
  enrollments?: IEnrollment[]
  eligibilities?: IEligibility[]
  isTagsSuccess?: boolean
  refetch: () => Promise<any>
  policies: string[]
}

const DriverForm = (props: DriverFormProps<IPersonMutationData>) => {
  const dispatch = useAppDispatch()

  const {
    eligibilities,
    enrollments,
    customerType,
    data,
    isSuccess,
    formik,
    onPreload,
    onReady,
    onResult,
    isTagsSuccess,
    initialTags = [],
    policies,
    refetch,
  } = props

  const newCommunication = useAppSelector(
    (state) => state.communication.newCommunication,
  )
  const deactivationCase = useAppSelector(
    (state) => state.deactivationCase.deactivationCaseData,
  )

  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)

  const userProfile = useAppSelector((state) => state.user.userProfile)
  const showTicketBlockInformation = useAppSelector(
    (state) => state.ticket.showTicketBlockInformation,
  )

  const currentOrganization = userProfile.organization

  const notifications = useNotifications()

  const { setShowManagerEmailTemplateModal } =
    useEmailSenderAndManagerEmailTemplateContext()
  const {
    setIsNewEmail,
    setIsSuccessSendEmail,
    setIsViewEmail,
    setEmailBody,
    setSubject,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const formWrapperRef = useRef(null)

  const [_, setShowTags] = useState<boolean>(false)
  const [showUpdatePerson, setShowUpdatePerson] = useState<boolean>(true)
  const [showDeletePerson, setShowDeletePerson] = useState<boolean>(false)
  const [showSuccessDeletePerson, setShowSuccessDeletePerson] =
    useState<boolean>(false)

  //TODO somehow refactor it or move to common
  useEffect(() => {
    //TODO mb refactor and remove it
    dispatch(setNewCommunication(false))
    dispatch(setDeactivationCase(null))
    dispatch(setShowTicketBlockInformation(false))
  }, [])

  const initialFormikValues = async (data: any) => {
    if (Object.keys(data).length) {
      formik.setValues(
        Object.entries(data).reduce(
          (a, [key, value]) => {
            if (key in a) {
              if (key === 'companies' && Array.isArray(value) && value.length) {
                a[key] = value.filter((item) => item !== '[]')
                return a
              }

              if (key === 'companies' && !Array.isArray(value)) {
                a[key] = [...(value as string).split(';')]
              } else if (key !== 'companies') {
                a[key] = value
              }

              if (
                key === 'companies' &&
                Array.isArray(value) &&
                !value.length
              ) {
                a[key] = value
              }

              if (key === 'phoneNumber') {
                a[key] = validateAndFormatPhoneNumber(value as string)
              }

              if (key === 'otherPhoneNumber' && Array.isArray(value)) {
                const valuePhone = Array.isArray(value) ? value[0] : ''
                a[key] = validateAndFormatPhoneNumber(valuePhone)
              }

              if (key === 'birthDate') {
                a[key] = dayjs(value as string).isValid()
                  ? dayjs.tz(value as string, 'UTC').format('MM/DD/YYYY')
                  : null
              }

              if (key === 'street') {
                a[key] = value ?? null
              }

              if (key === 'rating') {
                a[key] = value ?? 'Unrated'
              }

              if (key === 'city') {
                a[key] = value ?? null
              }

              if (key === 'state') {
                const newStateValue = Object.values(STATES).find(
                  ({ value: optionValue, match }) => {
                    const stringValue = value as string
                    if (
                      stringValue?.toUpperCase() ===
                        optionValue?.toUpperCase() ||
                      match.includes(stringValue?.toUpperCase())
                    ) {
                      return true
                    }
                    return false
                  },
                )
                a[key] = newStateValue?.value ?? null
              }

              if (key === 'postalCode') {
                a[key] = value ?? null
              }

              if (key === 'haveHealthInsurance') {
                a[key] = value ? `${value}`.toUpperCase() : null
              }

              if (key === 'coverageThroughTheState') {
                a[key] = value ? `${value}`.toUpperCase() : null
              }

              if (key === 'pay20PerMonth') {
                a[key] = value ? `${value}`.toUpperCase() : null
              }
            }
            return a
          },
          { ...formik.values } as any,
        ),
      )

      if (onPreload || typeof onPreload !== 'undefined') {
        await onPreload()
      }

      if (onReady) {
        onReady()
      }
    }
  }

  useEffect(() => {
    initialFormikValues(data)
  }, [data])

  useEffect(() => {
    if (newCommunication || deactivationCase || showTicketBlockInformation) {
      setShowSaveButton(false)
    }
    // Its wierd hack. I need to have organization in a context app.
    setShowTags(!!currentOrganization)
  }, [newCommunication, deactivationCase, showTicketBlockInformation])

  const handleClickTab = () => {
    if (newCommunication) {
      dispatch(setNewCommunication(false))
      setShowManagerEmailTemplateModal(false)
      setShowSaveButton(false)
      setIsNewEmail(true)
      setIsSuccessSendEmail(false)
      setIsViewEmail(false)
      setEmailBody('')
      setSubject('')
    }

    if (deactivationCase) {
      dispatch(setDeactivationCase(null))
    }

    if (showTicketBlockInformation) {
      dispatch(setShowTicketBlockInformation(false))
      setShowSaveButton(false)
    }
  }

  const handleSubmit = async () => {
    await formik.submitForm()

    const validationResult = await formik.validateForm()

    const formHaveErrors = Object.keys(validationResult).length > 0

    if (formHaveErrors) {
      notifications?.show('error', 'You have an error in the form.', {
        id: 'DriverFormError',
      })
      return false
    }

    setShowUpdatePerson(!showUpdatePerson)
    setShowSaveButton(!showSaveButton)
    refetch()
  }

  const handleSetShowUpdatePerson = () => {
    setShowUpdatePerson(!showUpdatePerson)
    setShowSaveButton(!showSaveButton)
    initialFormikValues(data)
  }

  const handleDeletePerson = () => {
    setShowDeletePerson(!showDeletePerson)
  }

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        title={
          isSuccess ? `${data.firstName ?? ''} ${data.lastName ?? ''}` : ''
        }
        titleClassName={styles.title}
        header={
          <DriverFormHeader
            customerType={customerType}
            setShowUpdatePerson={handleSetShowUpdatePerson}
            enrollments={enrollments}
            onDeletePerson={handleDeletePerson}
          />
        }
        headerClassName={styles.header}
      >
        <Portal.Target
          className={styles.toastPortal}
          id="driverFormHeaderNotification"
          media="desktop"
        />

        {isSuccess && (
          <div ref={formWrapperRef}>
            <Form providerValue={formik}>
              <MainInformation
                person={data}
                customerType={customerType}
                documents={data.documents}
                eligibilities={eligibilities}
              />
              <PolicyCheckHOC
                policyName="is_enable_add_tag"
                disablePolicyCheck={true}
              >
                <>
                  {isTagsSuccess && (
                    <MultiSelectTags
                      isLoading={props.isTagsLoading}
                      initialTags={initialTags || []}
                      recentlyTags={props.organizationTags || []}
                      onChange={(value: any) => {
                        if (!!props.onTagsUpdate) {
                          props.onTagsUpdate(value)
                        }
                      }}
                    />
                  )}
                </>
              </PolicyCheckHOC>

              <Tabs
                className={styles.tabs}
                onClick={handleClickTab}
                show={
                  !newCommunication &&
                  !deactivationCase &&
                  !showTicketBlockInformation
                }
              >
                <Tabs.Item id="basic" title="Basic Information">
                  <BasicInformation
                    showUpdatePerson={showUpdatePerson}
                    policies={policies}
                  />
                </Tabs.Item>

                <Tabs.Item
                  id="lead"
                  title={`${capitalize(customerType)} Information`}
                >
                  <LeadInformation customerType={customerType} />
                </Tabs.Item>
              </Tabs>

              {deactivationCase ? (
                <DeactivationBenefitCase data={deactivationCase} />
              ) : null}

              {newCommunication ? <EmailSenderAndManagerEmailTemplate /> : null}

              {showTicketBlockInformation ? <TicketInformation /> : null}

              <PolicyCheckHOC policyName="is_update_person_enabled">
                <>
                  {showSaveButton ? (
                    <div className={styles.saveWrapper}>
                      <Button
                        className={styles.cancelButton}
                        onClick={handleSetShowUpdatePerson}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={styles.saveButton}
                        iconAfter="ArrowSelect"
                        loading={formik.isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  ) : null}
                </>
              </PolicyCheckHOC>
            </Form>
          </div>
        )}
      </BasicBlock>

      {formik?.values?.email &&
      userProfile?.user?.username?.includes('drivingguild.org') ? (
        <DeactivationCases email={formik.values.email} />
      ) : null}

      {showDeletePerson && (
        <DeletePersonModal
          personEmail={formik.values.email ?? ''}
          personId={formik.values.id ?? ''}
          onClose={handleDeletePerson}
          onSuccessDelete={() => setShowSuccessDeletePerson(true)}
        />
      )}

      {showSuccessDeletePerson && (
        <SuccessDeletePersonModal
          onClose={() => setShowSuccessDeletePerson(false)}
        />
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DriverForm,
  'is_enable_driver_form',
  true,
)
