import { Button, Group } from '@mantine/core'
import { Link, RichTextEditor } from '@mantine/tiptap'
import { IconPaperclip, IconPhoto } from '@tabler/icons-react'
import Placeholder from '@tiptap/extension-placeholder'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect, useRef, useState } from 'react'

import { Spinner } from 'src/common/components'
import { useUploadAttachment } from 'src/common/hooks'
import { ModalResult } from 'src/default/components'

import { HtmlModeButton } from './components/HtmlModeButton'
import { HtmlMode } from './extensions/HtmlMode'
import { ImageResize } from './extensions/ImageResize'
import styles from './richText.module.scss'

type AttachmentType =
  | 'emails'
  | 'emails-images'
  | 'enrollment'
  | 'document'
  | 'person-file'

interface RichTextProps {
  value: string
  onChange: (value: string) => void
  readOnly?: boolean
  placeholder?: string
  showAttachments?: boolean
  onAttachmentUpload?: (
    files: { fileName: string; fileUrl: string; file: File }[],
  ) => void
  className?: string
  attachmentType?: AttachmentType
  imageType?: AttachmentType
  showToolbar?: boolean
  stickyOffset?: number
  showHtmlTextButton?: boolean
}

const RichText = ({
  value,
  onChange,
  readOnly = false,
  placeholder = 'Your Message...',
  showAttachments = true,
  onAttachmentUpload,
  className,
  attachmentType = 'emails',
  imageType = 'emails-images',
  showToolbar = true,
  stickyOffset = 60,
  showHtmlTextButton = false,
}: RichTextProps) => {
  const [error, setError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)

  const uploadImageMutation = useUploadAttachment({
    attachmentType: imageType,
  })

  const uploadFileMutation = useUploadAttachment({
    attachmentType: attachmentType,
  })

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      ImageResize,
      Placeholder.configure({
        placeholder,
        emptyEditorClass: styles.isEmpty,
      }),
      HtmlMode,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      if (editor.storage.htmlMode?.isHtmlMode) {
        onChange(editor.state.doc.textContent)
      } else {
        onChange(editor.getHTML())
      }
    },
    editable: !readOnly,
    onCreate: ({ editor }) => {
      editor.storage.htmlMode.isHtmlMode = false
    },
  })

  useEffect(() => {
    if (!editor) return

    const isHtmlMode = editor.storage.htmlMode?.isHtmlMode
    const currentContent = isHtmlMode
      ? editor.state.doc.textContent
      : editor.getHTML()

    if (value !== currentContent) {
      if (isHtmlMode) {
        editor.commands.setContent(value, false)
      } else {
        editor.commands.setContent(value)
      }
    }
  }, [value, editor])

  useEffect(() => {
    if (!readOnly && !editor?.isEditable) {
      editor?.setEditable(true)
    }

    return () => {
      if (editor?.storage.htmlMode?.isHtmlMode) {
        editor.storage.htmlMode.isHtmlMode = false
      }
    }
  }, [editor, readOnly])

  const uploadFiles = async (files: File[]) => {
    const attachments: { fileName: string; fileUrl: string; file: File }[] = []
    setIsUploading(true)

    try {
      await Promise.all(
        files.map(async (file) => {
          const result = await uploadFileMutation.mutateAsync({ file })
          const fileUrl = result?.request.responseURL.split('?')[0]
          attachments.push({ fileName: file.name, fileUrl, file })
        }),
      )

      onAttachmentUpload?.(attachments)
    } catch (error) {
      setError('We have a problem trying to upload the file. Please try again')
    } finally {
      setIsUploading(false)
    }
  }

  const uploadImages = async (files: File[]) => {
    setIsUploading(true)
    try {
      const uploadPromises = files.map(async (file) => {
        const result = await uploadImageMutation.mutateAsync({
          file,
        })
        return result?.request.responseURL.split('?')[0]
      })

      const uploadedUrls = await Promise.all(uploadPromises)
      return uploadedUrls
    } catch (error) {
      setError('Error uploading images, please contact support.')
      return []
    } finally {
      setIsUploading(false)
    }
  }

  const handleImageUpload = async (files: File[]) => {
    try {
      const imageUrls = await uploadImages(files)
      imageUrls.forEach((url) => {
        editor?.commands.setImage({ src: url })
      })
    } catch (error) {
      setError('Failed to process images and text')
    }
  }

  const handleAttachmentClick = () => {
    fileInputRef.current?.click()
  }

  const handleImageClick = () => {
    imageInputRef.current?.click()
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if (!files?.length) return

    const filteredFilesBySize = Array.from(files).filter(
      (file) => file.size < 5 * 1000000,
    )

    if (filteredFilesBySize.length !== files.length) {
      setError(
        "The file is too large and can't be uploaded (Max. 5 MB). Please reduce the size of the file and try again.",
      )
    }
    uploadFiles(filteredFilesBySize)
    event.target.value = ''
  }

  const handleImageInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if (!files?.length) return

    const filteredFiles = Array.from(files).filter(
      (file) => file.size < 5 * 1000000,
    )

    if (filteredFiles.length !== files.length) {
      setError('Some files are too large (Max. 5 MB)')
      return
    }

    await handleImageUpload(filteredFiles)
    event.target.value = ''
  }

  const isHtmlMode = editor?.storage.htmlMode?.isHtmlMode

  return (
    <div className={className}>
      {isUploading && (
        <div className={styles.loader}>
          <div className={styles.loaderWrapper}>
            <Spinner color="primary" size="big" />
            <label>Loading...</label>
          </div>
        </div>
      )}

      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf, image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />

      <input
        ref={imageInputRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleImageInputChange}
      />

      <RichTextEditor editor={editor}>
        {showToolbar && (
          <RichTextEditor.Toolbar sticky stickyOffset={stickyOffset}>
            {!isHtmlMode && (
              <>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />
                  <RichTextEditor.Hr />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.AlignLeft />
                  <RichTextEditor.AlignCenter />
                  <RichTextEditor.AlignJustify />
                  <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>

                {!readOnly && (
                  <Group>
                    <Button
                      variant="default"
                      size="xs"
                      onClick={handleImageClick}
                      leftSection={<IconPhoto size={16} />}
                    >
                      Image
                    </Button>
                    {showAttachments && (
                      <Button
                        variant="default"
                        size="xs"
                        onClick={handleAttachmentClick}
                        leftSection={<IconPaperclip size={16} />}
                      >
                        Attachment
                      </Button>
                    )}
                  </Group>
                )}
              </>
            )}

            {showHtmlTextButton && (
              <RichTextEditor.ControlsGroup>
                <HtmlModeButton editor={editor} />
              </RichTextEditor.ControlsGroup>
            )}
          </RichTextEditor.Toolbar>
        )}

        <RichTextEditor.Content />
      </RichTextEditor>

      {error ? (
        <ModalResult
          isSuccess={false}
          title="Upload Error!"
          description={error}
          onClose={() => setError(null)}
        />
      ) : null}
    </div>
  )
}

export default RichText
