import {
  Alert,
  Container,
  Flex,
  Group,
  Select,
  Text,
  TextInput,
  rem,
} from '@mantine/core'
import { DateInput, YearPickerInput } from '@mantine/dates'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import '@mantine/dropzone/styles.css'
import {
  IconInfoCircle,
  IconPhoto,
  IconUpload,
  IconX,
} from '@tabler/icons-react'
import { useState } from 'react'

import { Button } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import useRunProcessContainer from '../containers/useRunProcessContainer'
import { IRunProcessItem } from '../models/RunProcess.model'

interface RunReportModalModalProps extends IModal {
  initialData: IRunProcessItem
}

const RunProcessWithParametersModal = (props: RunReportModalModalProps) => {
  const { getFormProps, onSubmit, status, isLoading, form, formErrors } =
    useRunProcessContainer(props.initialData)
  const { initialData, onClose } = props

  const [fileState, setFileState] = useState<File | null>(null)
  const onCustomError = initialData?.onCustomError

  if (formErrors?.message) {
    if (
      typeof formErrors?.message === 'string' &&
      formErrors.message.match(onCustomError?.matchRegex || '')
    ) {
      return (
        <div>
          <Alert
            variant="light"
            color="red"
            title={formErrors.message}
            mb={10}
            icon={<IconInfoCircle />}
          >
            {onCustomError?.message}
          </Alert>
          <Flex
            mih={50}
            mt={20}
            gap="md"
            justify="flex-start"
            align="flex-start"
            direction="row"
          >
            <Button
              type="button"
              size="small"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              disabled={isLoading}
              onClick={() => {
                if (onCustomError?.values) {
                  form.setValues(onCustomError?.values)
                }
                onSubmit()
              }}
            >
              Proceed
            </Button>
          </Flex>
        </div>
      )
    }

    return (
      <div>
        <Alert
          variant="light"
          color="red"
          title="Error"
          mb={10}
          icon={<IconInfoCircle />}
        >
          {formErrors.message}
        </Alert>
        <Button type="submit" size="small" onClick={onClose}>
          Ok
        </Button>
      </div>
    )
  }

  return status === 'success' ? (
    <div>
      <p>{initialData?.successMessage ?? 'Success'}</p>
      <Button type="submit" size="small" onClick={onClose}>
        Ok
      </Button>
    </div>
  ) : (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault()

          if (fileState) {
            form.setValues({
              file: fileState,
            })
          }
          onSubmit()
        }}
      >
        {(initialData?.parameters?.length ?? 0) === 0 ? (
          <div>
            <p>No specific parameters are needed. You can RUN the process.</p>
          </div>
        ) : (
          initialData?.parameters.map((parameter) => {
            switch (parameter.type) {
              case 'date':
                return (
                  <DateInput
                    id={parameter.name}
                    name={parameter.name}
                    label={parameter.label}
                    size="lg"
                    mb={32}
                    {...getFormProps(parameter.name)}
                  />
                )
              case 'string':
                return (
                  <TextInput
                    id={parameter.name}
                    name={parameter.name}
                    label={parameter.label}
                    size="lg"
                    mb={32}
                    {...getFormProps(parameter.name)}
                  />
                )
              case 'file':
                return (
                  <>
                    <Dropzone
                      onDrop={(files) => {
                        setFileState(files[0])
                        form.setValues({
                          fileSource: parameter?.source || null,
                          saveFile: parameter?.saveFile || null,
                        })
                      }}
                      onReject={(files) => {
                        setFileState(null)
                      }}
                      maxSize={50 * 1024 ** 2}
                      accept={[MIME_TYPES.csv]}
                      maxFiles={1}
                      {...getFormProps(parameter.name)}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={80}
                        style={{ pointerEvents: 'none' }}
                      >
                        <Dropzone.Accept>
                          <IconUpload
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: 'var(--mantine-color-blue-6)',
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: 'var(--mantine-color-red-6)',
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: 'var(--mantine-color-dimmed)',
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Idle>

                        <div>
                          <Text size="sk" inline>
                            Drag file here or click to select files
                          </Text>
                          <Text size="sm" c="dimmed" inline mt={7}>
                            File should not exceed 50mb
                          </Text>
                        </div>
                      </Group>
                    </Dropzone>
                    <Text size="sm" c="dimmed">
                      {fileState?.name ?? null}
                    </Text>
                  </>
                )
              case 'select':
                return (
                  <Select
                    label={parameter.label}
                    placeholder="Pick value"
                    mt={15}
                    mb={25}
                    required={parameter.required}
                    data={parameter.options}
                    {...getFormProps(parameter.name)}
                  />
                )

              case 'year-picker':
                return (
                  <YearPickerInput
                    label={parameter.label}
                    placeholder="Pick year"
                    mt={15}
                    mb={25}
                    maxDate={new Date()}
                    type="default"
                    required={parameter.required}
                    {...getFormProps(parameter.name)}
                  />
                )

              default:
                return (
                  <TextInput name={parameter.name} label={parameter.label} />
                )
            }
          })
        )}
        <Flex
          mih={50}
          mt={10}
          gap="md"
          justify="flex-start"
          align="flex-start"
          direction="row"
        >
          <Button
            type="button"
            size="small"
            loading={false}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button type="submit" size="small" loading={isLoading}>
            Run
          </Button>
        </Flex>
      </form>
    </Container>
  )
}

export default RunProcessWithParametersModal
