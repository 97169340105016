import {
  Box,
  Button,
  Container,
  Flex,
  PasswordInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

import { ActionMenu, Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { CreateUser, userType } from '../../models/user.model'
import styles from './user-information.module.scss'

interface UserInformationProps {
  onSubmit: () => void
  editForm: boolean
  setEditForm: (values: boolean) => void
  handleCancelForm: () => void
  userInformationForm: UseFormReturnType<CreateUser>
  loading: boolean
  openDeleteUserModal?: () => void
}

const UserInformation = ({
  onSubmit,
  editForm,
  setEditForm,
  handleCancelForm,
  userInformationForm,
  loading,
  openDeleteUserModal,
}: UserInformationProps) => {
  return (
    <Box my="md">
      <Flex
        justify="space-between"
        align="flex-start"
        className={styles.headerWrapper}
      >
        <Text fw={700} c="blue.5">
          User Information
        </Text>
        {openDeleteUserModal && (
          <ActionMenu
            items={[
              {
                label: 'Update',
                color: 'blue',
                icon: <Icons.Edit />,
                onClick: () => setEditForm(true),
                enable: true,
              },
              {
                label: 'Delete',
                color: 'red',
                icon: <Icons.Trash />,
                onClick: () => openDeleteUserModal(),
                enable: true,
              },
            ]}
          />
        )}
      </Flex>
      <Flex justify="center">
        <Container mx="md" className={styles.flex1} my="lg">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <SimpleGrid cols={2} spacing="xl" verticalSpacing="xl">
              <TextInput
                key={userInformationForm.key('username')}
                label="username"
                withAsterisk
                readOnly={!editForm}
                {...userInformationForm.getInputProps('username')}
              />
              <TextInput
                key={userInformationForm.key('firstName')}
                label="First Name"
                withAsterisk
                readOnly={!editForm}
                {...userInformationForm.getInputProps('firstName')}
              />
              <TextInput
                key={userInformationForm.key('lastname')}
                withAsterisk
                readOnly={!editForm}
                label="Last Name"
                {...userInformationForm.getInputProps('lastName')}
              />
              <TextInput
                key={userInformationForm.key('phone')}
                withAsterisk
                readOnly={!editForm}
                label="Mobile Phone"
                {...userInformationForm.getInputProps('phone')}
              />
              <PasswordInput
                withAsterisk
                key={userInformationForm.key('password')}
                readOnly={!editForm}
                label="Password"
                {...userInformationForm.getInputProps('password')}
              />
              <TextInput
                key={userInformationForm.key('aircallUserId')}
                readOnly={!editForm}
                label="Aircall User ID"
                {...userInformationForm.getInputProps('aircallUserId')}
              />
              {/* commet cause at this moment the hippaa flag not work on BE */}
              {/* <Checkbox */}
              {/*   key={userInformationForm.key('hipaaCertified')} */}
              {/*   readOnly={!editForm} */}
              {/*   label="HIPPAA Certified" */}
              {/*   {...userInformationForm.getInputProps('hipaaCertified')} */}
              {/* /> */}
              <Select
                key={userInformationForm.key('type')}
                label="User Type"
                withAsterisk
                {...userInformationForm.getInputProps('type')}
                placeholder="Select User Type"
                data={userType}
                allowDeselect={false}
              />
            </SimpleGrid>
            {editForm && (
              <Flex justify="space-between" align="flex-start" mt="lg">
                <Button onClick={() => handleCancelForm()} variant="outline">
                  Cancel
                </Button>
                <Button loading={loading} type="submit" variant="filled">
                  Save
                </Button>
              </Flex>
            )}
          </form>
        </Container>
      </Flex>
    </Box>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserInformation,
  'is_enable_leads_table',
  true,
)
