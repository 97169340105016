// TODO: should be moved to organization domain
import { ApiResponseSerializer } from 'src/v2/commons/interface/ApiResponseSerializer'

import { OrganizationDTO } from '../../organization/dto/organization.dto'

type userType = 'regular' | 'external' | 'test' | 'integraion'

export const userType = [
  { value: 'regular', label: 'Regular' },
  { value: 'external', label: 'External' },
  { value: 'test', label: 'Test' },
  { value: 'integration', label: 'Integration' },
]

export interface User {
  id?: string
  firstName: string
  lastName: string
  username?: string
  hipaaCertified?: boolean
  aircallUserId?: string
  phone?: string
  password?: string
  membership?: UserMembership
  emailDomains?: MembershipEmailDomainUser[]
  type?: userType
}

export interface UserOrganization extends User {
  organizations: OrganizationDTO[]
}

export interface UserOrganizationV2 {
  id: string
  email: string
  name: string
  status: string
  role: string
}

export type UsersResponseModel = ApiResponseSerializer<UserOrganizationV2>

export interface UserMembership {
  id: string
  role: string
  hasAPIKey: boolean
  status: string
}

export interface MembershipEmailDomainUser {
  id: string
  type: 'BOTH' | 'SEND' | 'REPLY'
  name: string
  domain: string
  isWBFRegister: boolean
}

export interface TokenPayload {
  exp: number
  iat: number
  id: string
  membershipId: string
  organizationId: string
  parentTokenId: string
  sub: string
  userId: string
}

export interface MemberShipRole extends UserMembership {
  membershipRole: {
    id: string
    name: string
  }
}
export interface UserProfile {
  user: User
  membership: MemberShipRole
  organization: OrganizationDTO
  tokenPayload: TokenPayload
  policies: string[]
}

export const LocalStorageUserProfile = 'USER_PROFILE_SESSION'

export interface IUsersFilter {
  status?: string
  limit?: number
  offset?: number
  search?: string
  orderBy?: string
  userType?: userType
}

export type typeOrderBy = 'ASC' | 'DESC' | undefined

export interface CreateUser
  extends Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'username'
    | 'aircallUserId'
    | 'hipaaCertified'
    | 'password'
    | 'type'
  > {
  organizationId: string
}

export enum CreateUserError {
  WEAK_PASSWORD = `Password MUST contain:

  - At least 1 special character
  - At least 1 digit
  - At least 1 Uppercase
  - At least 1 Lowercase`,
}
